import { useContext } from 'react';
import { Pagination } from '@mui/material';
import { getEntityID, getQueryParam} from '../Functions';
import { AppContext, PenpanContext } from '../Context';

const Controls = () => {
  const appContext = useContext(AppContext);
  const penpanContext = useContext(PenpanContext);
  const t = appContext.t;
  const pageNo = penpanContext.entityPage ? penpanContext.entityPage : 1;
  const totalPages = penpanContext.totalPages ? penpanContext.totalPages : 1;
  const returnURL = penpanContext.returnURL ? penpanContext.returnURL : null;
  const drawable = penpanContext.drawable;

  const isAuthenticated = appContext.isAuthenticated;
  const ready = appContext.ready;
  const isFinished = penpanContext.isFinished;
  const setPageNo = penpanContext.setEntityPage;
  const finish = penpanContext.finish;
  const results = penpanContext.results;
  const submitPage = penpanContext.submitPage;
  const chooseEntity = penpanContext.chooseEntity;
  const loading = appContext.loading;
  const controlsRef = penpanContext.controlsRef;

  return (
    <>
      {
        ready && 
        isAuthenticated && 
        (
          <div 
            className="d-block position-absolute left w-100 m-0 p-0 text-center" 
            style={{bottom: '0', minHeight: '50px', position:drawable ?"relative":"", zIndex:drawable ?"-20":""}}
             ref={controlsRef}
          >
            { getEntityID() && window.innerWidth >= 800 &&
              <Pagination 
                count={totalPages} 
                page={pageNo} 
                color="primary"
                size="large"
                onChange={async (e, page) => {

                  await submitPage(pageNo);
                    
                    if ( page !== pageNo ) {
                      setPageNo(page);

                    } else {
                      loading(false);
                    }  
                  }
                } 
              />
            }
            { getEntityID() && window.innerWidth < 800 &&
              (
                <ul className={`d-inline-block m-0 mb-2 p-0 pagination   `} style={{  position:drawable ?"relative":"", zIndex:drawable ?"-20":""}}>
	            		<li className="d-inline-block page prev"
                      onClick={async () => {
                        await submitPage(pageNo);

                        if ( pageNo > 1 ) {
                          setPageNo(pageNo - 1);

                        } else {
                          loading(false);
                        }
                      }
                    }
                  >
                    <i className="arrow left"></i>
                  </li>
		            	<li className="d-inline-block page-info">
                    {`${pageNo} / ${totalPages}`}
                  </li>
	  	          	<li className="d-inline-block page next"
                      onClick={async () => {
                          await submitPage(pageNo);

                          if ( pageNo < totalPages ) {
                            setPageNo(pageNo + 1);

                          } else {
                            loading(false);
                          }
                        }
                      }
                    >
                    <i className="arrow right"></i>
                  </li>
	            	</ul>
              )
            }
            { getEntityID() &&
              ( isFinished ?
               <a
                  href={getQueryParam('return') ?getQueryParam('return'):"#"}
                  className="btn btn-success position-absolute results"
                  style={{top: '0', right: '5px'}}
                >
                  { t('Results') }
                </a>
                :
                <button 
                  onClick={async ()=>{
                    await submitPage(pageNo,true);
                     finish()
                  }}
                  className="btn btn-danger position-absolute finish" 
                  style={{top: '0', right: '5px'}}
                >
                  { t('Finish') }
                </button>
              )
            }

            {/*<button
              onClick={chooseEntity} 
              className="btn btn-primary position-absolute entity-menu" 
              style={{top: '0', left: '5px'}}>
              <FontAwesomeIcon icon={faBookOpen} />
            </button>*/}
          </div>
        )
      }
     {
        /*returnURL &&
        <a className="d-block position-absolute left-0 ps-2"
           style={{top: '50px', fontSize: '30px'}}
           href={returnURL}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </a>
      */}
    </>
  );
};
  
export default Controls;