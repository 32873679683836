import { useState, useEffect, useContext } from 'react';
import DOMPurify from 'dompurify';
import { getImageDimensions } from '../Functions';
import { ModalContext } from '../Context';

const Content = ({
  data 

}) => {
  const modalContext = useContext(ModalContext);
  const containerRef = modalContext.containerRef;
  const headerRef = modalContext.headerRef;
  const footerRef = modalContext.footerRef;
  const [contentHeight, setContentHeight] = useState(null);
  const [imgStyles, setImgStyles] = useState(null);

  // Initialization when the component mounts for the first time or the modal parts are changed
  useEffect(() => {
    let cHeight = containerRef.current.offsetHeight - 
      headerRef.current.offsetHeight - 
      footerRef.current.offsetHeight;

    setContentHeight(cHeight);

    if ( data.type === 'image' && data.object ) {
      (async () => {
        let image = await getImageDimensions(data.object);

        if ( (image.height / image.width) > (cHeight / containerRef.current.offsetWidth) ) {
          setImgStyles({
            width: 'auto',
            height: '100%'
          });
  
        } else {
          setImgStyles({
            width: '100%',
            height: 'auto'
          });
        }
        
      })();
    }

  }, [containerRef, headerRef, footerRef, data]);

  return (
    <div className={(data.type === 'text' ? 'd-block' : 'd-flex') + ' m-0 p-0'} style={{height: contentHeight}}>
      {
        data.type === 'text' &&
        <div className="p-3 pt-0" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data.object)}} />       
      }

      {
        data.type === 'image' &&
        <img src={data.object} alt={data.title} className="m-0 ms-auto me-auto" style={imgStyles} />       
      }

      {
        data.type === 'pdf' &&
        <iframe src={
                  (window.innerWidth < 800 ? 'https://docs.google.com/gview?url=' + data.object + '&embedded=true' : data.object)
                } 
                title={data.title} 
                className="w-100 h-100 m-0 p-0" />
      }

      {
        data.type === 'video' &&
        <iframe src={data.object} title={data.title} className="w-100 h-100 m-0 p-0" />
      }
    </div>
  );
};

export default Content;