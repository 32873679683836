import { useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Entities from './Entities';
import Results from './Results';
import Content from './Content';
import { AppContext, ModalContext } from '../Context';

const ModalContent = () => {
  const appContext = useContext(AppContext);
  const modal = appContext.modal;
  const closeModal = appContext.closeModal;
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  const footerRef = useRef(null);

  return (
    <ModalContext.Provider value={{ 
      containerRef,
      headerRef,
      footerRef
    }}>
      <section className="h-100 shadow modal-wrapper">
        <FontAwesomeIcon
            icon={faXmark}
            onClick={closeModal}
            className="position-absolute"
            style={{cursor: 'pointer', fontSize: '40px', right: '-15px', top: '-20px', color: '#777'}}
        />
        <div ref={containerRef} className="d-flex flex-column h-100 overflow-auto modal-container">
          <header ref={headerRef} className="m-0 pt-3 pb-2">
            <h2 className="text-center m-0 p-2">{modal.title}</h2>
          </header>

          <ModalContentBody
            modal={modal}
          />

          <footer ref={footerRef} className="d-flex mt-auto p-0">
            {
              /*
              <button onClick={closeModal} 
                      className="btn btn-secondary">close</button>
              */
            }
          </footer>
        </div>
      </section>
    </ModalContext.Provider>
  );
};

const ModalContentBody = ({
  modal

}) => {
  const appContext = useContext(AppContext);
  const t = appContext.t;

  if ( modal.type === 'content' ) {
    return (
      <Content
        data={modal.data}
      />
    );

  } else if ( modal.type === 'entities' ) {
    return (
      <Entities
        data={modal.data}
      />
    );

  } else if ( modal.type === 'results' ) {

    return (
      <Results
        data={modal.data}
      />
    );

  } else if ( modal.type === 'finished' ) {

    return (
      <div className="modal-content p-3">
        <p className="h3 text-center text-success">{ t('Exam Finished Successfully!') }</p>
        <p className="h5 text-center text-primary">{ t('Now, you can check the exam result.') }</p>
      </div>
    );

  } else if ( modal.type === 'finish' ) {
    return (
      <div className="modal-content">
        <h2 className="text-center mb-5">Sınav Bitti </h2>
         <p className="mt-4 text-center">
           Birazdan Sonuçlar Aktif Olacak
        </p>
      </div>
    );

  } else if ( modal.type === 'exception'  ) {
    return (
      <div className="modal-content p-3">
        <p className="text-justify">{ modal.data.message }</p>
      </div>
    );

  } else {
    return (
      <div className="modal-content" dangerouslySetInnerHTML={{__html: modal.data}} />
    );
  }  
};
 
export default ModalContent;