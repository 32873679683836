import getPkce from 'oauth-pkce';
import {deleteCookie, getCookie} from "cookies-next";
import axios from "axios";

const API_CLIENT_ID               = process.env.REACT_APP_API_CLIENT_ID;
const API_URL_AUTHORIZE           = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_AUTHORIZE;
const API_URL_TOKEN               = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_TOKEN;
const API_URL_USER                = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_USER;
const API_URL_LOGOUT              = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_LOGOUT;
const API_URL_QBOOK_HTML_PAGE     = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_QBOOK_HTML_PAGE;
const API_URL_ALL_QBOOK_DATA      = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_ALL_QBOOK_DATA;
const API_URL_ALL_EXAM_DATA       = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_ALL_EXAM_DATA;
const API_URL_QBOOK_DATA          = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_QBOOK_DATA;
const API_URL_EXAM_DATA           = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_EXAM_DATA;
const API_URL_EXAM_ITEMS_DATA     = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_EXAM_ITEMS_DATA;
const API_URL_SEND_PAGE_Q_ANSWERS = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_SEND_PAGE_Q_ANSWERS;
const API_URL_SEND_PAGE_DRAWING   = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_SEND_PAGE_DRAWING;
const API_URL_ENTITY_DRAWING      = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_ENTITY_DRAWING;
const API_URL_FINISH_EXAM         = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_FINISH_EXAM;
const API_URL_CHECK_EXAM          = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_CHECK_EXAM;
const API_URL_RESULT_STATS        = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_RESULT_STATS;
const API_URL_PENPAN_PAGE         = process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_PATH_PENPAN;
const PARENT_PANEL_URL            = process.env.REACT_APP_PARENT_PANEL_URL;
const PARENT_PANEL_URL_LOGOUT     = process.env.REACT_APP_PARENT_PANEL_URL + process.env.REACT_APP_PARENT_PANEL_PATH_LOGOUT;
const TargetHeight                = 1600;
const TargetWidth                 = 1600;

const getExamResultStats = async (type, id) => {
  let headers = getGeneralHeaders();

  if ( ! headers ) return;
  
  return await fetch(
    API_URL_RESULT_STATS + '/' + type.toLowerCase() + '/md5/' + id,
    {
      method: 'GET',
      headers: headers
    }

  ).then(response => {
    return responseX(response, 'Could not get exam result statistics!');
  
  }).catch(rejected => {
    return exceptionX(rejected);
  });
};

const finishExam = (type, id) => {
  let headers = getGeneralHeaders('post');

  console.log("sınavbitti")

  if ( ! headers ) return;

  return axios.get( API_URL_FINISH_EXAM + '/' + type.toLowerCase() + '/md5/' + id,  )
      .then(async response => {
     return responseX(response, 'Could not finish exam!');

  }).catch(rejected => {
    const url = getQueryParam('return')
          console.log("err",rejected)
         if (url) {
          window.location.replace(url)
        }else{
           window.location.reload()
         }

  });
};

const sendPageDrawing = async (pageNo, image) => {
  let entity = getEntity(null, null, pageNo, true);

  if ( ! entity.type ) return;
  if ( ! entity.id ) return;

  let headers = getGeneralHeaders('post');

  if ( ! headers ) return;  

  return await axios.put(
    API_URL_SEND_PAGE_DRAWING,
      {
        entity_type: entity.type,
        entity_md5_id: entity.id,
        entity_page: pageNo,
        image: image
      }

  ).then(response => {
    return responseX(response, 'Could not save page drawing!');
  
  }).catch(rejected => {
    console.log("rejected",rejected)
   /* return exceptionX(rejected);*/
  });
};

const sendPageQuestionAnswers = async (pageNo,finish=false) => {
  let entity = getEntity(null, null, pageNo, true);
   if ( ! entity.type ) return;
  if ( ! entity.id ) return;
  if ( ! entity.activity ) return;

  let activity = entity.activity;

  if ( ! activity[pageNo] ) return;

  let page = activity[pageNo];

  if ( ! page.questions ) return;

  let questions = page.questions;

  if ( Object.keys(questions).length === 0 ) return;

  let headers = getGeneralHeaders('post');

  if ( ! headers ) return;
   const questionPutFunc =async (data)=> {
      await axios.put(
           API_URL_SEND_PAGE_Q_ANSWERS,
           data
       ).then(async response => {
            if (response.data != 1 && data.finish == true){

                await questionPutFunc(data)
           }else{

                data.finish == true && window.location.reload();
            }
       }).catch(rejected => {
           console.log("rejected", rejected)
           /* return exceptionX(rejected);*/
       });
   }
    await axios.put(
        API_URL_SEND_PAGE_Q_ANSWERS,
        {
            entity_type: entity.type,
            entity_md5_id: entity.id,
            questions: questions,
            finish: false
        }
    )
     return  await questionPutFunc({
        entity_type: entity.type,
        entity_md5_id: entity.id,
        questions: questions,
        finish: finish
    })
};

const createTargetPage = async (type = null, id = null, page = 0) => {
  let entity = getEntity(type, id, page);
   if ( ! entity.type ) {
    return exceptionX('Entity Type has not been specified!');
  }

  if ( ! entity.id ) {
    return exceptionX('Entity ID has not been specified!');
  }

  if ( ! entity.page ) {
    entity.page = 1;
  }

  entity.type = entity.type.toLowerCase();
  entity.image = null;
  entity.exceptions = [];


  if ( !['questionbook', 'exam'].includes(entity.type) ) {
    return exceptionX('Invalid Entity Type!');
  }

  return await getTargetPage(entity, true);
};

const getTargetPage = async (entity, oneRequest = true) => {

   if ( oneRequest ) {
    return await getPenpanPageData(entity.type, entity.id, entity.page)
    .then( async response => {

      if ( response.status ) {
        if ( response.data ) {

          let data = response.data.entity.data;

          let result = response.data?.result?.data || false;
          let result_items = response?.data?.result_items?.map((result_items) =>(result_items.data)) || [];
          let pageQuestions = {};
          let pageItems = [];
          entity.mainID = data.id;
          entity.data = data;

          if ( entity.type !== 'questionbook' ) {
            entity.total_pages = data.page_count;
            pageItems = data.page.data.items ? data.page.data.items : [];
          }

           pageItems?.forEach((itemObj) => {
             let item = itemObj.data
            let userAnswer = parseInt(item.user_answer);
            pageQuestions[item.md5_id] = {};
            pageQuestions[item.md5_id].answer = userAnswer;
          });


          entity.activity[entity.page] = entity.activity[entity.page] ? entity.activity[entity.page] : {};
          entity.activity[entity.page].questions = pageQuestions;

           entity.image = response.data?.drawing?.data?.image ? response.data.drawing.data.image : null;

           entity.finished = result;
           entity.result_items = result_items

            if ( entity.type === 'exam' ) {
             entity.data.items = result_items;

            if ( entity.page > entity.total_pages ) entity.page = entity.total_pages;
          }

          setEntity(entity);

          return await createTargetHTMLPage(entity);

        } else {

          return exceptionX('Could not get target page data!');
        }

      } else {

        return response;
      }

    }).catch(error => {
      return exceptionX(error);
    });

  } else {

    return getTargetPageData(entity.type.toLowerCase(), entity.id, entity.page)
    .then( async response => {
      if ( response.status ) {
        if ( response.data ) {
          return await processEntityData(entity, response.data)
          .then(pEntity => {
            return createTargetHTMLPage(pEntity);
          })
          .catch(error => {
            return exceptionX(error);
          });
  
        } else {
          return exceptionX('Could not get target page data!');
        }
  
      } else {
        return response;
      }
  
    }).catch(error => {
      return exceptionX(error);
    });
  }  
};

const processEntityData = async (entity, data) => {
  entity.mainID = data.id;
  entity.data = data;
  let pageQuestions = {};
  let pageItems = [];

  if ( entity.type !== 'questionbook' ) {
    entity.total_pages = data.page_count;
    pageItems = data.page.data.items ? data.page.data.items : [];
  }


  pageItems?.forEach((itemObj) => {
     let item = itemObj.data
    let userAnswer = parseInt(item.user_answer);
    pageQuestions[item.md5_id] = {};
    pageQuestions[item.md5_id].answer = userAnswer;
  });


  entity.activity[entity.page] = entity.activity[entity.page] ? entity.activity[entity.page] : {};
  entity.activity[entity.page].questions = pageQuestions;

  await getTargetPageDrawing(entity.type, entity.mainID, entity.page)
  .then(response => {
    if ( response && response.status ) {
      let data = response.data;

      if ( data && data.image ) {
        entity.image = data.image;
      }
    }
  })
  .catch(error => {
    entity.exceptions.push(exceptionX(error));
  });

  await examFinished(entity.type, entity.id)
  .then(async response => {
    if ( typeof(response) === 'object' ) {
      if ( response.status ) {
        entity.finished = response.data;

        if ( entity.type.toLowerCase() === 'exam' ) {
          let res = await getExamAllItemsData(entity.id);
          entity.data.items = [];

          if ( res && res.status ) {
            entity.data.items = res.data;

          } else {
            entity.exceptions.push(res);
          }
        }

      } else {
        entity.finished = false;
      }

    } else if ( response === false ) {
      entity.finished = false;
    }            
  })
  .catch(error => {
    entity.exceptions.push(exceptionX(error));
  });

  if ( entity.type.toLowerCase() === 'exam' && entity.page > entity.total_pages ) entity.page = entity.total_pages;

  setEntity(entity);

  return entity;
};

const createTargetHTMLPage = async (entity) => {
   let page = getEntityPage(true);
    if ( entity.type.toLowerCase() === 'questionbook' ) {

     if (entity.pages && entity.pages[page] && entity.pages[page].html) {


       if ( entity.page > entity.total_pages ) entity.page = entity.total_pages;
      if ( page > entity.total_pages ) page = entity.total_pages;
  
      let pageQuestions = {};
      let pageItems = {};

        entity.pages[page].items?.forEach((itemData) => {
         pageItems[itemData.object.md5_id] = itemData.object;
      });


      for (const itemObj of entity?.data.items) {
          const item = itemObj.data

        if ( pageItems[item.md5_id] ) {
          pageItems[item.md5_id] = item;
        }
      }


         for (const md5_id in pageItems) {

         pageQuestions[md5_id] = {};
        pageQuestions[md5_id].answer =  parseInt(pageItems[md5_id].user_answer);
       }
  
      entity.activity[entity.page] = entity.activity[entity.page] ? entity.activity[entity.page] : {};
      entity.activity[entity.page].questions = pageQuestions;


      setEntity(entity);

      return await entity.pages[page].html;
    }

    let pages = {};
    let qWidth = 540;
    let qPageHeight = 1500;
    let i = 0;
    let p = 1;


    for (const itemObj of entity?.data.items) {
      const item = itemObj?.data

       if ( !item.exam_item?.data || !item.exam_item?.data?.exam_page.data || !item.exam_item?.data?.exam_page?.data?.page_image_url ) {
        continue;
      }

      i++;
      let image = await getImageDimensions(
        item.exam_item?.data.exam_page?.data?.page_image_url // + '?' + generateRandomString(20)
      );
      let ratio = TargetHeight / image.height;
      let answerData = {};

      let q_x0_val = Math.round(parseInt(item.exam_item?.data['question_x0']) * ratio);
      let q_y0_val = Math.round(parseInt(item.exam_item?.data['question_y0']) * ratio);
      let q_x1_val = Math.round(parseInt(item.exam_item?.data['question_x1']) * ratio);
      let q_y1_val = Math.round(parseInt(item.exam_item?.data['question_y1']) * ratio);
      let minX = q_x0_val;
      let minY = q_y0_val;
      let maxX = q_x0_val;
      let maxY = q_y0_val;

      if ( q_x1_val < minX ) minX = q_x1_val;
      if ( q_y1_val < minY ) minY = q_y1_val;
      if ( q_x1_val > maxX ) maxX = q_x1_val;
      if ( q_y1_val > maxY ) maxY = q_y1_val;

      for (const v of ['a', 'b', 'c', 'd', 'e']) {
        answerData[v] = {}

        let x0_var = 'answer_' + v + '_x0';
        let y0_var = 'answer_' + v + '_y0';
        let x1_var = 'answer_' + v + '_x1';
        let y1_var = 'answer_' + v + '_y1';

        answerData[v].x0 = Math.round(parseInt(item.exam_item?.data[x0_var]) * ratio);
        answerData[v].y0 = Math.round(parseInt(item.exam_item?.data[y0_var]) * ratio);
        answerData[v].x1 = Math.round(parseInt(item.exam_item?.data[x1_var]) * ratio);
        answerData[v].y1 = Math.round(parseInt(item.exam_item?.data[y1_var]) * ratio);


        if ( answerData[v].x0 > 0 || answerData[v].y0 > 0 || answerData[v].x1 > 0 || answerData[v].y1 > 0 ) {
          if ( answerData[v].x0 < minX ) minX = answerData[v].x0;
          if ( answerData[v].x1 < minX ) minX = answerData[v].x1;
          if ( answerData[v].y0 < minY ) minY = answerData[v].y0;
          if ( answerData[v].y1 < minY ) minY = answerData[v].y1;
          if ( answerData[v].x0 > maxX ) maxX = answerData[v].x0;
          if ( answerData[v].x1 > maxX ) maxX = answerData[v].x1;
          if ( answerData[v].y0 > maxY ) maxY = answerData[v].y0;
          if ( answerData[v].y1 > maxY ) maxY = answerData[v].y1;
        }
      }

      let itemData = {
        left: minX,
        top: minY,
        width: maxX - minX,
        height: maxY - minY,
        row_no: 1,
        object: item,
        html: ''
      };
      let preItem = null;

       if ( !pages[p] ) {
        pages[p] = {
          height: 0,
          items: [],
          html: '<div class="qPage">'
        };
      }


      if ( pages[p].items.length > 0 ) {

        preItem = pages[p].items[pages[p].items.length - 1];

         if ( preItem.row_no < 2 && itemData.width < qWidth ) {


          if ( preItem.width < qWidth ) {

            if ( itemData.height > preItem.height ) {

              if ( pages[p].height + itemData.height - preItem.height < qPageHeight ) {
                itemData.row_no = 2;
                pages[p].height += itemData.height - preItem.height;

              } else {
                pages[p].html += '</div>';
                preItem = null;
                pages[++p] = {
                  height: 0,
                  items: [],
                  html: '<div class="qPage">'
                };
                pages[p].height += itemData.height;
              }

            } else {
              itemData.row_no = 2;
            }

          } else {
            if ( pages[p].height + itemData.height < qPageHeight ) {
              pages[p].height += itemData.height;

            } else {
              pages[p].html += '</div>';
              preItem = null;
              pages[++p] = {
                height: 0,
                items: [],
                html: '<div class="qPage">'
              };
              pages[p].height += itemData.height;
            }
          }

        } else {


          if ( pages[p].height + itemData.height < qPageHeight ) {

            pages[p].height += itemData.height;

          } else {
            pages[p].html += '</div>';
            preItem = null;
            pages[++p] = {
              height: 0,
              items: [],
              html: '<div class="qPage">'
            };
            pages[p].height += itemData.height;
          }
        }

      } else {
        pages[p].height += itemData.height;
      }
       itemData.html += itemData.row_no < 2 ? (preItem && preItem.row_no < 2 ? '</div><div class="d-block clearfix mt-2">' : '<div class="d-block clearfix mt-2">') : '';
      itemData.html += '<div class="que"' + (itemData.width < qWidth ? '' : ' style="width: auto;"') + '>';
      itemData.html += '<i>' + i + ')</i>';
      itemData.html += '<div style="';
      itemData.html += 'position: relative;';
      itemData.html += 'background-repeat: no-repeat;';
      itemData.html += 'background-size: ' + Math.round(ratio * image.width) + 'px ' + Math.round(ratio * image.height) + 'px;';
      itemData.html += 'background-image: url(' + item.exam_item?.data.exam_page?.data?.page_image_url + ');';
      itemData.html += 'background-position: -' + itemData.left + 'px -' + itemData.top + 'px;';
      itemData.html += 'width: ' + itemData.width + 'px;';
      itemData.html += 'height: ' + itemData.height + 'px;';
      itemData.html += 'margin-left: 12px;';
      itemData.html += '">';
      itemData.html += '<div class="qBox">';
      itemData.html += '<div data-imd5="' + item.md5_id + '" class="answerBox">';

      for (const v of ['a', 'b', 'c', 'd', 'e']) {
         if ( answerData[v].x0 > 0 || answerData[v].y0 > 0 || answerData[v].x1 > 0 || answerData[v].y1 > 0 ) {
          let left = answerData[v].x0 - minX - 5;
          let top = answerData[v].y0 - minY - 5;
          let width = answerData[v].x1 - answerData[v].x0;
          let height = answerData[v].y1 - answerData[v].y0;
  
          itemData.html += '<a href="#">';
          itemData.html += '<div class="area" style="';
          itemData.html += 'top: ' + top + 'px;';
          itemData.html += 'left: ' + left + 'px;';
          itemData.html += 'width: ' + width + 'px;';
          itemData.html += 'height: ' + height + 'px;';
          itemData.html += '">';
          itemData.html += '</div>';
          itemData.html += '</a>';
        }
      }

      itemData.html += '</div></div></div></div>';
      itemData.html += itemData.row_no > 1 ? '</div>' : '';
      pages[p].html += itemData.html;



      pages[p].items.push(itemData);

    }

    entity.total_pages = p;

    if ( entity.page > entity.total_pages ) entity.page = entity.total_pages;
    if ( page > entity.total_pages ) page = entity.total_pages;

    let pageQuestions = {};
     pages[page]?.items?.forEach((itemData) => {

      pageQuestions[itemData.object.md5_id] = {};
      pageQuestions[itemData.object.md5_id].answer = parseInt(itemData.object.user_answer);

      });
     entity.activity[entity.page] = entity.activity[entity.page] ? entity.activity[entity.page] : {};
    entity.activity[entity.page].questions = pageQuestions;
    entity.pages = pages;

      setEntity(entity);

    return pages[page]?.html;

  } else if ( entity.data.page && entity.data.page?.data.page_image_url ) {

       if (entity.pages && entity.pages[page] && entity.pages[page].html) return entity.pages[page].html;

    let image = await getImageDimensions(
      entity.data.page.data.page_image_url // + '?' + generateRandomString(20)
    );

    let HTML = '<div class="qPage text-center"><div class="d-inline-block position-relative h-100 m-auto p-0">';
    HTML += '<img src="' + entity.data.page?.data.page_image_url + '" class="d-block h-100 m-0" />';

     if ( !image.height ) {
      HTML += '</div></div>';

      return HTML;
    }

    let ratio = TargetHeight / image.height;
    let answerData = {};

     for (const itemObj of entity.data.page.data.items) {

      const item = itemObj.data


      let q_x0_val = Math.round(parseInt(item['question_x0']) * ratio);
      let q_y0_val = Math.round(parseInt(item['question_y0']) * ratio);
      let q_x1_val = Math.round(parseInt(item['question_x1']) * ratio);
      let q_y1_val = Math.round(parseInt(item['question_y1']) * ratio);
      let minX = q_x0_val;
      let minY = q_y0_val;
      let maxX = q_x0_val;
      let maxY = q_y0_val;

      if ( q_x1_val < minX ) minX = q_x1_val;
      if ( q_y1_val < minY ) minY = q_y1_val;
      if ( q_x1_val > maxX ) maxX = q_x1_val;
      if ( q_y1_val > maxY ) maxY = q_y1_val;

      for (const v of ['a', 'b', 'c', 'd', 'e']) {
        answerData[v] = {}
         let x0_var = 'answer_' + v + '_x0';
        let y0_var = 'answer_' + v + '_y0';
        let x1_var = 'answer_' + v + '_x1';
        let y1_var = 'answer_' + v + '_y1';


        answerData[v].x0 = Math.round(parseInt(item[x0_var]) * ratio);
        answerData[v].y0 = Math.round(parseInt(item[y0_var]) * ratio);
        answerData[v].x1 = Math.round(parseInt(item[x1_var]) * ratio);
        answerData[v].y1 = Math.round(parseInt(item[y1_var]) * ratio);


        if ( answerData[v].x0 > 0 || answerData[v].y0 > 0 || answerData[v].x1 > 0 || answerData[v].y1 > 0 ) {
          if ( answerData[v].x0 < minX ) minX = answerData[v].x0;
          if ( answerData[v].x1 < minX ) minX = answerData[v].x1;
          if ( answerData[v].y0 < minY ) minY = answerData[v].y0;
          if ( answerData[v].y1 < minY ) minY = answerData[v].y1;
          if ( answerData[v].x0 > maxX ) maxX = answerData[v].x0;
          if ( answerData[v].x1 > maxX ) maxX = answerData[v].x1;
          if ( answerData[v].y0 > maxY ) maxY = answerData[v].y0;
          if ( answerData[v].y1 > maxY ) maxY = answerData[v].y1;
        }
      }



      let left = minX;
      let top = minY;
      let width = maxX - minX;
      let height = maxY - minY;
      top = top - Math.round(45 * (top/1600));
      left = left - Math.round(40 * (left/1250));

      HTML += '<div class="que position-absolute m-0" style="';
      HTML += 'top: ' + top + 'px;';
      HTML += 'left: ' + left + 'px;';
      HTML += 'width: ' + width + 'px;';
      HTML += 'height: ' + height + 'px;';
      HTML += '">';
      HTML += '<div class="qBox"><div class="answerBox" data-imd5="' + item.md5_id + '">';


      for (const v of ['a', 'b', 'c', 'd', 'e']) {
         if ( answerData[v].x0 > 0 || answerData[v].y0 > 0 || answerData[v].x1 > 0 || answerData[v].y1 > 0 ) {
          left = answerData[v].x0 - minX;
          top = answerData[v].y0 - minY;
          width = answerData[v].x1 - answerData[v].x0;
          height = answerData[v].y1 - answerData[v].y0;
          top = top - Math.round(0.155 * (top/height));
          left = left - Math.round(0.3 * (left/width));

          HTML += '<a href="#">';
          HTML += '<div class=" position-absolute area" style="';
          HTML += 'top: ' + top + 'px;';
          HTML += 'left: ' + left + 'px;';
          HTML += 'width: ' + width + 'px;';
          HTML += 'height: ' + height + 'px;';
          HTML += '">';
          HTML += '</div>';
          HTML += '</a>';
        }
      }

      HTML += '</div></div></div>';
    }
     for (const contentObj of entity.data.page.data.contents ) {
      const content = contentObj.data
      let title = content.content_title;
      let object = content.object_url;
      let left = Math.round(parseInt(content.px) * ratio);
      let top = Math.round(parseInt(content.py) * ratio);
      let type = 'text';
      let icon = '<svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 512 512"><path d="M512 240c0 114.9-114.6 208-256 208c-37.1 0-72.3-6.4-104.1-17.9c-11.9 8.7-31.3 20.6-54.3 30.6C73.6 471.1 44.7 480 16 480c-6.5 0-12.3-3.9-14.8-9.9c-2.5-6-1.1-12.8 3.4-17.4l0 0 0 0 0 0 0 0 .3-.3c.3-.3 .7-.7 1.3-1.4c1.1-1.2 2.8-3.1 4.9-5.7c4.1-5 9.6-12.4 15.2-21.6c10-16.6 19.5-38.4 21.4-62.9C17.7 326.8 0 285.1 0 240C0 125.1 114.6 32 256 32s256 93.1 256 208z"/></svg>';

      if ( parseInt(content.type) === 0 ) {
        type = 'video';
        icon = '<svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM48 368v32c0 8.8 7.2 16 16 16H96c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16zm368-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V368c0-8.8-7.2-16-16-16H416zM48 240v32c0 8.8 7.2 16 16 16H96c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16zm368-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H416zM48 112v32c0 8.8 7.2 16 16 16H96c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H64c-8.8 0-16 7.2-16 16zM416 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H416zM160 128v64c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H192c-17.7 0-32 14.3-32 32zm32 160c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H320c17.7 0 32-14.3 32-32V320c0-17.7-14.3-32-32-32H192z"/></svg>';

      } else if ( parseInt(content.type) === 1 ) {
        type = 'pdf';
        icon = '<svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 512 512"><path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V304H176c-35.3 0-64 28.7-64 64V512H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z"/></svg>';

      } else if ( parseInt(content.type) === 2 ) {
        type = 'image';
        icon = '<svg xmlns="http://www.w3.org/2000/svg" height="3em" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"/></svg>';
      }
      if (entity.finished !==false || content.isTopic == true) {
        HTML += '<div title="' + title + '" data-type="' + type + '" data-object="' + object + '" class=" position-absolute content-button ' + type + '"  style="';
        HTML += 'top: ' + top + 'px;';
        HTML += 'left: ' + left + 'px;';
        HTML += 'width: 50px;';
        HTML += 'height: 50px;';
        HTML += '">';
        HTML += icon;
        HTML += '</div>';
      }
    }

    HTML += '</div></div>';

    entity.pages[page] = {};
    entity.pages[page].html = HTML;

    setEntity(entity);

    return HTML;

  } else {
    return exceptionX('Invalid Entity Type!');
  }
};

const getQBookHTMLPage = async (entity) => {
  if ( entity.type.toLowerCase() !== 'questionbook' ) {
    return exceptionX('The requested entity is not a question book!');
  }

  let headers = getGeneralHeaders('get', false);
  
  if ( ! headers ) return;
  
  return await axios.get(
    API_URL_QBOOK_HTML_PAGE + '/' + entity.id + '/' + entity.page,

  
  ).then(response => {
    return response
    
  }).catch(rejected => {
    return exceptionX(rejected);
  });
};  

const examFinished = (type, id) => {
  let headers = getGeneralHeaders();

  if ( ! headers ) return;
  
  return axios.get(API_URL_CHECK_EXAM + '/' + type.toLowerCase() + '/md5/' + id,  ).then(async response => {


    if ( response.status === 404 ) {
      return false;

    } else {
      return responseX(response, 'Could not check if the exam finished!');
    }
  
  }).catch(rejected => {

    return exceptionX(rejected);
  });
};

const getTargetPageDrawing = async (type, mainID, page = 1) => {
  let headers = getGeneralHeaders();

  if ( ! headers ) return;
  
  return await axios.get(
    API_URL_ENTITY_DRAWING + '/' + type.toLowerCase() + '/' + mainID + '/' + page,
  ).then(response => {

    return responseX(response, 'Could not get page drawing!');
  
  }).catch(rejected => {
    return exceptionX(rejected);
  });
};

const getPenpanPageData = async (type, md5_id, page = 1) => {
  let headers = getGeneralHeaders();

  if ( ! headers ) return;
  
  return await axios.get(
    API_URL_PENPAN_PAGE + '/' + type + '/' + md5_id + '/' + page, ).then(response => {

    return responseX(response, 'Could not get page data!');
  
  }).catch(rejected => {
    return exceptionX(rejected);
  });
};

const getTargetPageData = async (type, md5_id, page = 1) => {
  let EP = type === 'questionbook' ? API_URL_QBOOK_DATA : API_URL_EXAM_DATA;
  let headers = getGeneralHeaders();

  if ( ! headers ) return;
  
  return await axios.get(
    EP + '/' + md5_id + '?page=' + page,


  ).then(response => {
    return responseX(response, 'Could not get page data!');
  
  }).catch(rejected => {
    return exceptionX(rejected);
  });
};

const getExamAllItemsData = (md5_id) => {
  let headers = getGeneralHeaders();

  if ( ! headers ) return;
  
  return axios.get(
    API_URL_EXAM_ITEMS_DATA + '/' + md5_id,


  ).then(response => {
    return responseX(response, 'Could not get exam all items data!');
  
  }).catch(rejected => {
    return exceptionX(rejected);
  });
};

const getAllExamData = () => {
  let headers = getGeneralHeaders();

  if ( ! headers ) return;

  const params = new URLSearchParams({
    limit: 20
  });
  
  return axios.get(
    API_URL_ALL_EXAM_DATA + '?' + params.toString(),


  ).then(response => {
    return responseX(response, 'Could not get all exam data!');
  
  }).catch(rejected => {
    console.log("rejected",rejected)
  });
};

const getAllQBookData = () => {
  let headers = getGeneralHeaders();

  if ( ! headers ) return;

  const params = new URLSearchParams({
    limit: 20
  });
  
  return axios.get(
    API_URL_ALL_QBOOK_DATA + '?' + params.toString(),


  ).then(response => {
    return responseX(response, 'Could not get all question book data!');
  
  }).catch(rejected => {
    return exceptionX(rejected);
  });
};

const resetPageActivityDrawing = (pageNo) => {
  let entities = getEntities();
   if ( !entities ) return;

  let entity = getEntity(null, null, pageNo, true);
  let activity = entity.activity ? entity.activity : {};
  let page = activity[pageNo] ? activity[pageNo] : {};
  page.drawing = [];
  activity[pageNo] = page;
  entity.activity = activity;
  entities[entity.type.toLowerCase()][entity.id] = entity;

  localStorage.setItem('entities', JSON.stringify(entities));
};

const setPageAllQuestionAnswers = (pageNo, questions) => {
  if ( ! questions ) questions = {};

  let entities = getEntities();

  if ( ! entities ) return;

  let entity = getEntity(null, null, pageNo, true);
  let activity = entity.activity ? entity.activity : {};
  let page = activity[pageNo] ? activity[pageNo] : {};
  page.questions = questions;
  activity[pageNo] = page;
  entity.activity = activity;
  entities[entity.type.toLowerCase()][entity.id] = entity;
  localStorage.setItem('entities', JSON.stringify(entities));


};

const setPageActivityDrawing = (pageNo, drawing) => {
  let entities = getEntities();

  if ( ! entities ) return;

  let entity = getEntity();

  if ( ! entity.type ) return;
  if ( ! entity.id ) return;

  let activity = entity.activity ? entity.activity : {};
  let page = activity[pageNo] ? activity[pageNo] : {};

  page.drawing = drawing;
  activity[pageNo] = page;
  entity.activity = activity;
  entities[entity.type.toLowerCase()][entity.id] = entity;

   localStorage.setItem('entities', JSON.stringify(entities));
};

const setPageActivityQuestionAnswer = (pageNo, MD5ID, answer) => {
  let entities = getEntities();
   if ( ! entities ) return;

  let entity = getEntity();
   if ( ! entity.type ) return;
  if ( ! entity.id ) return;

  let activity = entity.activity ? entity.activity : {};
  let page = activity[pageNo] ? activity[pageNo] : {};
  let questions = page.questions ? page.questions : {};
  let q = questions[MD5ID] ? questions[MD5ID] : {};
  q.answer = answer;
  questions[MD5ID] = q;  
  page.questions = questions;
  activity[pageNo] = page;
  entity.activity = activity;
  entities[entity.type.toLowerCase()][entity.id] = entity;

  localStorage.setItem('entities', JSON.stringify(entities));
};

const getPageActivityDrawing = (pageNo) => {
  let entity = getEntity();

  if ( ! entity.type ) return null;
  if ( ! entity.id ) return null;

  let activity = entity.activity ? entity.activity : {};
  let page = activity[pageNo] ? activity[pageNo] : {};
  let drawing = page.drawing ? page.drawing : [];

  return drawing;
};

const getPageActivityQuestionById = (pageNo, MD5ID) => {
  let entity = getEntity();
  if ( !entity.type ) return null;
  if ( !entity.id ) return null;


  let activity = entity.activity ? entity.activity : {};
  let page = activity[pageNo] ? activity[pageNo] : {};

  let questions = page.questions ? page.questions : {};
  let q = questions[MD5ID] ? questions[MD5ID] : {};
  return q;
};

const getPageActivityQuestions = (pageNo) => {
  let entity = getEntity();

  if ( ! entity.type ) return null;
  if ( ! entity.id ) return null;

  let activity = entity.activity ? entity.activity : {};
  let page = activity[pageNo] ? activity[pageNo] : {};
  let questions = page.questions ? page.questions : {};

  return questions;
};

const getPageActivity = (pageNo) => {
  let activity = getActivity();

  if ( ! activity ) return null;

  let page = activity[pageNo] ? activity[pageNo] : {};

  return page;
};

const getActivity = () => {
  let entity = getEntity();
   return entity.activity;
};

const getEntity = (type = null, id = null, page = 0, local = false) => {
  if ( !type ) type = getEntityType(local);
  if ( !id ) id = getEntityID(local);
  if ( !page ) page = getEntityPage(local);

  let entities = getEntities();

  let entity = {
    type: type,
    id: id,
    page: page > 0 ? page : 1,
    mainID: 0,
    image: null,
    data: {},
    total_pages: 1,
    activity: {},
    finished: false,
    pages: {},
    exceptions: []
  };


  if ( type ) type = type.toLowerCase();
  
  if ( type && id &&
      entities.hasOwnProperty(type) && typeof entities[type] === 'object' &&
      entities[type].hasOwnProperty(id) && typeof entities[type][id] === 'object' &&
      entities[type][id].type && entities[type][id].id ) {

    let result = entities[type][id];

    entity.type = result.type;
    entity.id = result.id;
    entity.data = result.data ? result.data : {};
    entity.mainID = result?.data.id ? result.data.id : 0;
    entity.image = result.image ? result.image : null;
    entity.total_pages = result.total_pages ? result.total_pages : 1;
    entity.activity = result.activity ? result.activity : {};
    entity.finished = !result.finished || result.finished === 'false' ? false : result.finished;
    entity.pages = result.pages ? result.pages : {};
    entity.exceptions = result.exceptions ? result.exceptions : [];
   } else {
    setEntity(entity);
  }
  return entity;
};

const setEntity = (entity) => {
  if ( entity.type && entity.id ) {
     let entities = getEntities();
     if ( !entities[entity.type.toLowerCase()] ) entities[entity.type.toLowerCase()] = {};

    entities[entity.type.toLowerCase()][entity.id] = entity;

     localStorage.setItem('entity_type', entity.type);
    localStorage.setItem('entity_id', entity.id);
    localStorage.setItem('entity_page', entity.page);
    localStorage.setItem('entities', JSON.stringify(entities));

   }
   return entity;
};

const getEntityPage = (local = false) => {
  let entityPage = parseInt(
    localStorage.getItem('entity_page')
  );
  let paramPage = parseInt(
    getQueryParam('page')
  );
  let page = ! local ? paramPage : 0;
  page = page ? page : (entityPage ? entityPage : 1);

  return page;
};

const getEntityID = (local = false) => {
  let id = ! local && getQueryParam('type') ? getQueryParam('id') : null;

  return id ? id : localStorage.getItem('entity_id');
};

const getEntityType = (local = false) => {
  let type = ! local && getQueryParam('id') ? getQueryParam('type') : null;

  return type ? type : localStorage.getItem('entity_type');
};

const getReturnURL = (local = false) => {
  let ret = ! local && getQueryParam('return') ? getQueryParam('return') : null;

  if ( ret ) {
    localStorage.setItem('return_url', ret);
  }

  return localStorage.getItem('return_url');
};

const getEntities = () => {
  let entities;

  try {
    entities = JSON.parse(localStorage.getItem('entities'));

    if ( ! entities ) entities = {};

  } catch (e) {
    entities = {};
  }

  return entities;
};

const isUserAuthenticated = async (verify = true) => {
  if ( verify ) await checkAuthenticated();

  if ( localStorage.getItem('isAuthenticated') === 'true' ) {
    return true;

  } else {
    return false;
  }  
};

const logout = async (parent = false) => {
  let token = getToken();

  if ( ! token ) {
    window.location.replace(
      getBaseURL()
    );
    return;
  }

  return await axios.get( API_URL_LOGOUT,  ).then(response => {
    if ( response.status !== 200 ) {
      alert('Logout Failed!');
    }
    
    if ( parent && getReturnURL(true) ) {
      localStorage.clear();
      window.location.replace(
        PARENT_PANEL_URL_LOGOUT
      );

    } else {
      localStorage.clear();
      window.location.replace(getBaseURL());
    }

    return response ;

  }).catch(rejected => {
    return exceptionX(rejected);
  });
};

const checkAuthenticated = async () => {
  localStorage.setItem('isAuthenticated', 'false');

  let token = getToken();
   if ( ! token ) {
    return;
  }

  await axios.get(  API_URL_USER,  ).then(async response => {
   if( !response.data.id){
     localStorage.clear()
     deleteCookie("access_token")
    }

    if ( response.status === 200 ) {

      const idQuery = getQueryParam("id")
      if(idQuery != localStorage.getItem('entity_id')){
        localStorage.clear()
      }

      localStorage.setItem('isAuthenticated', 'true');

      return response;

    } else {
      localStorage.setItem('isAuthenticated', 'false');


      await axios.post(   API_URL_TOKEN,
          {
          client_id: process.env.REACT_APP_API_CLIENT_ID,
          grant_type: "refresh_token",
          refresh_token: token.refresh_token
        }
      ).then(response => {
        if( !response.data.id){

          localStorage.setItem('isAuthenticated', 'false');


        } else {
          localStorage.setItem('isAuthenticated', 'true');

          return response
        }

        return; 

      }).then(async data => {
        if (data) await setToken(data);
       
      }).catch(rejected => {
        console.log("access_token")

        localStorage.clear()
        deleteCookie("access_token")
        return exceptionX(rejected);
      });
    }

    return;

  }).then(data => {
    if (data) setUser(data);

  }).catch(rejected => {
    console.log("access_token")
    localStorage.clear()
    deleteCookie("access_token")
    return exceptionX(rejected);
  });
};

const validate = async () => {
  let token = getToken();

  if ( ! token ) {
    await authorize();
    return;
  }

  await axios.get(  API_URL_USER,  ).then(response => {
    if( !response.data.id){
      localStorage.setItem('isAuthenticated', 'false');
      refresh();

    } else {
     localStorage.setItem('isAuthenticated', 'true');
    }

    return response;
  })
  .then(data => {
    setUser(data);

  }).catch(rejected => {
    return exceptionX(rejected);
  });
};

const refresh = async () => {
  let token = getToken();

  if ( ! token ) {
    await authorize();
    return;
  }


  await axios.post(
    API_URL_TOKEN,
      {
        client_id: process.env.REACT_APP_API_CLIENT_ID,
        grant_type: "refresh_token",
        refresh_token: token.refresh_token
      }


  ).then(response => {
    if( !response.data.id){

      localStorage.setItem('isAuthenticated', 'false');
      authorize();
    } else {
      localStorage.setItem('isAuthenticated', 'true');
    }

    return response;

  }).then(async data => {
    await setToken(data);

  }).catch(rejected => {
    return exceptionX(rejected);
  });
};

const authorize = async () => {
  var state;

  if ( ! getQueryParam('code') || ! getQueryParam('state') ) {
    localStorage.setItem('isAuthenticated', 'false');
    getReturnURL();

    state = generateRandomString(40);

    getPkce(128, (error, { verifier, challenge }) => {
      if (error) throw error;

      localStorage.setItem('state', state);
      localStorage.setItem('codeVerifier_' + state, verifier);

      const params = new URLSearchParams({
        client_id: API_CLIENT_ID,
        redirect_uri: getBaseURL(),
        response_type: 'code',
        scope: '',
        state: state,
        code_challenge: challenge,
        code_challenge_method: 'S256'
      });

      window.location.replace(API_URL_AUTHORIZE + "?" + params.toString());
    });

  } else {
    state = localStorage.getItem('state');
    let authorizeState = localStorage.getItem('authorize_' + state);

    if ( authorizeState === 'true' || ! state ) {
      return;
    }
    
    localStorage.setItem('authorize_' + state, 'true');

    if ( getQueryParam('state') !== state ) {
      throw new Error('Authentication state is not valid!');
    }

    let codeVerifier = localStorage.getItem('codeVerifier_' + state);

    await axios.post(
      API_URL_TOKEN,
        {
          client_id: API_CLIENT_ID,
          redirect_uri: getBaseURL(),
          grant_type: "authorization_code",
          code_verifier: codeVerifier,
          code: getQueryParam('code')
        }

    ).then(response => {
      if( !response.data.id){

        localStorage.setItem('isAuthenticated', 'false');
      } else {
        localStorage.setItem('isAuthenticated', 'true');
      }

      return response;

    }).then(async data => {
      await setToken(data);
  
    }).catch(rejected => {
      return exceptionX(rejected);
    });
  }
};

const getGeneralHeaders = (method = 'get', json = true) => {  
  let token = getToken();

  if ( ! token ) return null;

  let headers = {
    Authorization: 'Bearer ' + token.access_token
  };

  if ( method === 'post' ) headers['Content-Type'] = 'application/json';
  if ( json ) headers['Accept'] = 'application/json';

  return headers;
};  

const getUser = () => {
  let user = null;

  try {
    user = JSON.parse(localStorage.getItem('user'));

  } catch (e) {
    user = null;
  }

  if ( ! user || typeof user.name === 'undefined' ) {
    user = null;
  }

  if ( ! user ) {
    localStorage.removeItem('user');
  }

  return user;
};

const setUser = (data) => {
  if ( localStorage.getItem('isAuthenticated') === 'true' ) {
    localStorage.setItem('user', JSON.stringify(data));
  }
};

const getToken = () => {
  let token = null;
  if (getCookie("access_token")) {
    token = {access_token:getCookie("access_token"  )  }
    return  token

  }else {

    try {
      token = JSON.parse(localStorage.getItem('token'));

    } catch (e) {
      token = null;
    }

    if (!token || typeof token.access_token === 'undefined' || typeof token.refresh_token === 'undefined') {
      token = null;
    }

    if (!token) {
      localStorage.removeItem('token');
    }

    return token;
  }
};

const setToken = async (data) => {
  if ( localStorage.getItem('isAuthenticated') === 'true' ) {
    let ret = getReturnURL();

    localStorage.clear();
    localStorage.setItem('token', JSON.stringify(data));

    if ( ret ) localStorage.setItem('return_url', ret);

    await validate();
  }
};

const simulateEvent = (element, eventName, args) => {
  const eventMatchers = {
    'HTMLEvents': /^(?:load|unload|abort|error|select|change|submit|reset|focus|blur|resize|scroll)$/,
    'MouseEvents': /^(?:click|dblclick|mouse(?:down|up|over|move|out))$/
  };
  var defaultOptions = {
    clientX: 0,
    clientY: 0,
    layerX: 0,
    layerY: 0,
    movementX: 0,
    movementY: 0,
    offsetX: 0,
    offsetY: 0,
    pageX: 0,
    pageY: 0,
    screenX: 0,
    screenY: 0,
    button: 0,
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    metaKey: false,
    bubbles: true,
    cancelable: true
  };
  const extend = (destination, source) => {
    for (var property in source)
      destination[property] = source[property];
    
    return destination;
  };

  var options = extend(defaultOptions, args || {});
  var oEvent, eventType = null;

  for (var name in eventMatchers) {
    if ( eventMatchers[name].test(eventName) ) {
      eventType = name; 
      
      break;
    }  
  }

  if ( ! eventType )
    throw new SyntaxError('Only HTMLEvents and MouseEvents interfaces are supported');

  if ( document.createEvent ) {
    if ( eventType === 'HTMLEvents' ) {
      oEvent = new Event(eventName, {
        bubbles: options.bubbles, 
        cancelable: options.cancelable
      });

    } else {
      oEvent = new MouseEvent(eventName, {
        view: document.defaultView,
        bubbles: options.bubbles, 
        cancelable: options.cancelable,
        altKey: options.altKey,
        ctrlKey: options.ctrlKey,
        shiftKey: options.shiftKey,
        metaKey: options.metaKey,
        button: options.button,
        clientX: options.clientX,
        clientY: options.clientY,
        layerX: options.layerX,
        layerY: options.layerY,
        movementX: options.movementX,
        movementY: options.movementY,
        offsetX: options.offsetX,
        offsetY: options.offsetY,
        pageX: options.pageX,
        pageY: options.pageY,
        screenX: options.screenX,
        screenY: options.screenY
      });
    }
        
    element.dispatchEvent(oEvent);

  } else {
    var evt = document.createEventObject();
    oEvent = extend(evt, options);

    element.fireEvent('on' + eventName, oEvent);
  }
    
  return element;
};

const getBuffer = (maxUndos) => {
  if ( ! maxUndos ) maxUndos = Infinity;
  
  var buffer = [];
  var position = 0;
  
  const Controller = {
    get position() { 
      return position;
    },

    get buffer() {
      return buffer;
    },

    set buffer(value) {
      buffer = value;
      position = buffer.length;
    },

    get canUndo() {
      return position > 0;
    },
  
    get canRedo() {
      return position < buffer.length;
    },
  
    update(data) {
      if ( position === maxUndos ) {
        buffer.shift();
        position--;
      }
  
      if ( position < buffer.length ) {
        buffer.length = position;
      }
  
      buffer.push(data);
      position++;
    },
  
    undo(all = true) {
      if ( this.canUndo ) {
        if (all) {
          const buf = [...buffer];
          buf.length = --position;
  
          return buf;
        }
  
        return buffer[--position];
      }

      return [];
    },
  
    redo() {
      if ( this.canRedo ) {
        return buffer[position++];
      }

      return [];
    },

    current() {
      return buffer[position] ? buffer[position] : [];
    }
  };
  
  return Controller;
};

const drawStrokes = async (ctx, strokes) => {
  if ( typeof strokes === 'string' ) {
    if ( strokes === '<<TRASHED>>' ) {
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    } else {
      await setCanvasImage(ctx, strokes);
    }

  } else if ( typeof strokes.points !== 'undefined' && typeof strokes.color !== 'undefined' && typeof strokes.width !== 'undefined' ) {
    let points = strokes.points;
    let i = 0;

    if ( strokes.draw ) {
      ctx.beginPath();

      ctx.globalAlpha = strokes.opacity;
      ctx.strokeStyle = strokes.color;
      ctx.lineWidth = strokes.width;

      while ( i < points.length ) {
        ctx.lineTo(points[i].x, points[i].y);
        i++;
      }

      ctx.stroke();

    } else {
      let lineWidth = strokes.width;

      while ( i < points.length ) {
        ctx.clearRect(points[i].x - lineWidth / 2, points[i].y - lineWidth / 2, lineWidth, lineWidth);
        i++;
      }
    }
  }
};

const setCanvasImage = (ctx, data) => {
   return new Promise((resolve, reject) => {
    const img = new Image()

    // the following handler will fire after a successful loading of the image
    img.onload = () => {
      ctx.drawImage(img, 0, 0);

      resolve({
        width: img.naturalWidth,            
        height: img.naturalHeight 
      });
    }

    // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
    img.onerror = () => {
      reject('There was some problem with the image.')
    }

    img.src = data;
  });
};

const getImageDimensions = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image()

    // the following handler will fire after a successful loading of the image
    img.onload = () => {
      resolve({
        width: img.naturalWidth,            
        height: img.naturalHeight 
      });
    }

    // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
    img.onerror = () => {
      reject('There was some problem with the image.')
    }

    img.src = url;
  });
};

const getQueryParam = (param) => {
  const searchParams = new URLSearchParams(document.location.search);

  return searchParams.get(param);
};

const getCurrentPath = () => {
  return window.location.pathname.replace(/^\//gm, '').replace(/\/+$/gm, '');
};

const getBaseURL = () => {
  return (window.location.protocol + '//' + window.location.host).replace(/\/+$/g, '');
};

const responseX = async (response, message = null) => {

   let data =response

  if ( response.status !== 200 ) {
    return exceptionX((message ? message + ' ' : '') + data.message, 'error', response.status);
  }
   return {
    status: true,
    data: data.data
  };
};

const exceptionX = (message, level = 'error', code = '000') => {

  localStorage.clear();
  window.location.replace("/")
  let exception = {
    status: false,
    level: level,
    code: code,
    message: message
  };

  console.log(exception);

  return exception;
};

const generateRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let result = '';

  // Create an array of 32-bit unsigned integers
  const randomValues = new Uint32Array(length);
  
  // Generate random values
  window.crypto.getRandomValues(randomValues);

  randomValues?.forEach((value) => {
    result += characters.charAt(value % charactersLength);
  });

  return result;
};

const sleep = ms => new Promise(r => setTimeout(r, ms));

const undoBuffer = getBuffer();

export {
  getExamResultStats,
  examFinished,
  finishExam,
  getTargetPageDrawing,
  sendPageDrawing,
  sendPageQuestionAnswers,
  resetPageActivityDrawing,
  setPageAllQuestionAnswers,
  setPageActivityDrawing,
  getPageActivityDrawing,
  setPageActivityQuestionAnswer,
  getPageActivityQuestionById,
  getPageActivityQuestions,
  getPageActivity,
  getActivity,
  createTargetPage,
  getQBookHTMLPage,
  getAllExamData,
  getAllQBookData,
  getEntityPage,
  getEntityID,
  getEntityType,
  getReturnURL,
  getEntity,
  logout,
  getUser,
  isUserAuthenticated,
  checkAuthenticated,
  validate,
  authorize,
  simulateEvent,
  getBuffer,
  drawStrokes,
  setCanvasImage,
  getImageDimensions,
  getQueryParam,
  getCurrentPath,
  getBaseURL,
  exceptionX,
  generateRandomString,
  sleep,
  PARENT_PANEL_URL,
  TargetWidth,
  TargetHeight,
  undoBuffer
};

