import { useContext } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { getBaseURL } from '../Functions';
import { AppContext } from '../Context';

const Entities = ({
  data

}) => {
  const appContext = useContext(AppContext);
  const t = appContext.t;

  const getQBooks = () => {
    let content = [];
    data?.q_books?.forEach((itemObj) => {
    const item  = itemObj.data
      let link = getBaseURL() + '?type=questionbook&id=' + item.md5_id + '&page=1';

      content.push(
        <Item
          type="questionbook"
          item={item}
          link={link}
          key={'questionbook-' + item.id}
        />
      )
    });

    return content;
  };

  const getExams = () => {
    let content = [];
    data?.exams?.forEach((itemObj) => {
      const item  = itemObj.data
      let link = getBaseURL() + '?type=exam&id=' + item.md5_id + '&page=1';

      content.push(
        <Item
          type="exam"
          item={item}
          link={link}
          key={'exam-' + item.id}
        />
      )
    });

    return content;
  };

  return (
    <div className="container items-wrapper mb-3">
      <Tabs>
        <TabList>
          <Tab>{ t('Question Books') }</Tab>
          <Tab>{ t('Exams') }</Tab>
        </TabList>

        <TabPanel>
          <div className="row">{getQBooks()}</div>
        </TabPanel>
        <TabPanel>
          <div className="row">{getExams()}</div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

const Item = ({
  type,
  item,
  link

}) => {
  const appContext = useContext(AppContext);
  const t = appContext.t;

  return (
    <div id={type + '-' + item.id} className="col-md-4 col-sm-12 mt-3 mb-1">
      <a href={link} className="d-block p-2 border rounded border-primary link-underline-light text-dark">
        <p className="text-primary">{item.fullname}</p>
        
        { type === 'questionbook' && 
          <p className="text-muted">
            { t('Total Questions') }: 
            <span className="large text-primary"> { item.question_count }</span>
          </p>
        }

        { type !== 'questionbook' && 
          <p className="text-muted">
            { t('Total Pages') }: 
            <span className="large text-primary"> { item.page_count }</span>
          </p>
        }

        <p className="text-muted">
          { t('Created') }: 
          <span className="small text-primary"> {item.created_date}</span>
        </p>
      </a>
    </div>
  );
};

export default Entities;