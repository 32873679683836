import React,{useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios   from "axios";
import { getCookie, setCookie } from "cookies-next";

axios.defaults.baseURL = process.env.NEXT_APP_API_BASE_URL;
axios.defaults.headers.common = {
    Authorization: `Bearer ${getCookie("access_token")}`,
};
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <div style={{
          position: "absolute",
          top: "60px",
          left: "10px",
          cursor: "pointer",
          zIndex: "100",
          background:"#ccc",
          borderRadius:"100%",
          padding:"3px 5px 3px"
      }}
           onClick={() => window.history.go(-1)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               className="feather feather-arrow-left">
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
      </div>
      <App/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();