import { useState, useContext } from 'react';
import {getQueryParam, /*getExamResultStats,*/ PARENT_PANEL_URL} from '../Functions';
import { AppContext } from '../Context';

const Results = ({data}) => {

  const appContext = useContext(AppContext);
  //const setException = appContext.setException;
  const t = appContext.t;
  //const loading = appContext.loading;
  const [stats, setStats] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [showStats, setShowStats] = useState(false);
   const getItems = () => {
    let content = [];
    let i = 1;

     data.entity.data.items.forEach((itemObj) => {
      const item  = itemObj.data
       content.push(
        <Item
          no={i}
          item={item}
          type={data.entity.type}
          key={item.id}
        />
      );

      i++;
    });

    return content;
  };

  return (
    <div className="container mb-4">
      <div className="result-info">
        {/*<div className="row">
          <div className="col-6 small text-primary d-flex align-items-center justify-content-end">
            { t('Score') }:
          </div>
          <div className="col-6 h1 text-primary text-start">
            {data.entity.finished.score}
          </div>
        </div>
        <div className="row">
          <div className="col-6 small text-success d-flex align-items-center justify-content-end">
            { t('Correct Answers') }:
          </div>
          <div className="col-6 h1 text-success text-start">
            {data.entity.finished.correct_answers}
          </div>
        </div>
        <div className="row">
          <div className="col-6 small text-danger d-flex align-items-center justify-content-end">
            { t('Wrong Answers') }:
          </div>
          <div className="col-6 h1 text-danger text-start">
            {data.entity.finished.wrong_answers}
          </div>
        </div>
        <div className="row">
          <div className="col-6 small text-secondary d-flex align-items-center justify-content-end">
            { t('Empty Answers') }:
          </div>
          <div className="col-6 h1 text-secondary text-start">
            {data.entity.finished.empty_answers}
          </div>
        </div>
        <div className="row">
          <div className="col-6 small text-info d-flex align-items-center justify-content-end">
            { t('Total Questions') }:
          </div>
          <div className="col-6 h1 text-info text-start">
            {
              parseInt(data.entity.finished.correct_answers) + 
              parseInt(data.entity.finished.wrong_answers) + 
              parseInt(data.entity.finished.empty_answers)
            }
          </div>
        </div>*/}
        <div className="mt-4 text-center">
           <a
            href={getQueryParam('return') ?getQueryParam('return'):"#"}
            className="btn btn-success"
            >
            { t('Show Details') }
          </a>

          {/*
          <button
            onClick={(e) => {
                setShowDetails(! showDetails);
                setShowStats(false);
              }
            } 
            className={ `btn ${(showDetails ? 'btn-secondary' : 'btn-primary')} mt-2` }
          >
            { showDetails ? t('Hide Details') : t('Show Details') }
          </button>
          <button
            onClick={async (e) => {
                if ( ! showStats ) {
                  loading(true);

                  let response = await getExamResultStats(data.entity.type, data.entity.id);

                  if ( response.status ) {
                    setStats(response.data);
  
                  } else {
                    setException(response);
                  }

                  loading(false);
                }

                setShowStats(! showStats);
                setShowDetails(false);
              }
            }
            className={ `btn ${(showStats ? 'btn-secondary' : 'btn-primary')} mt-2 ms-3` }
          >
            { showStats ? t('Hide Stats') : t('Show Stats') }
          </button>
          */}
        </div>
      </div>
      { showDetails &&
        <div className="mt-4">
          <h3 className="text-center text-secondary mb-3">{ t('Details') }</h3>
          <h4 className="text-center text-primary mb-3">{ data.entity.data.fullname }</h4>
          <table className="table m-auto" style={{maxWidth: '500px'}}>
            <thead>
              <th className="text-secondary">#</th>
              <th className="text-secondary">{ t('Answer') }</th>
              <th className="text-secondary">{ t('Correct Answer') }</th>
              <th className="text-secondary">{ t('Score') }</th>
            </thead>
            <tbody>
              {getItems()}
            </tbody>
          </table>
        </div>
      }
       { showStats && stats &&
        <div className="mt-4">
          <h3 className="text-center text-secondary mb-3">{ t('Statistics') }</h3>
          <h4 className="text-center text-primary mb-3">{ data.entity.data.fullname }</h4>
          <div className="m-auto" style={{maxWidth: '500px'}}>
            <StatItem
              title={ data.entity.finished.student.data.class.fullname }
              count={ stats.class.count }
              userRank={ stats.class.user_rank }
              score={ stats.class.average.score }
              correctAnswers={ stats.class.average.correct_answers }
              wrongAnswers={ stats.class.average.wrong_answers }
              emptyAnswers={ stats.class.average.empty_answers }
            />

            <StatItem
              title={ data.entity.finished.student.data.department.fullname }
              count={ stats.department.count }
              userRank={ stats.department.user_rank }
              score={ stats.department.average.score }
              correctAnswers={ stats.department.average.correct_answers }
              wrongAnswers={ stats.department.average.wrong_answers }
              emptyAnswers={ stats.department.average.empty_answers }
            />

            <StatItem
              title={ data.entity.finished.student.data.branch.user.name }
              count={ stats.branch.count }
              userRank={ stats.branch.user_rank }
              score={ stats.branch.average.score }
              correctAnswers={ stats.branch.average.correct_answers }
              wrongAnswers={ stats.branch.average.wrong_answers }
              emptyAnswers={ stats.branch.average.empty_answers }
            />
             <StatItem
              title={ data.entity.finished.student.data.customer.data.user.name }
              count={ stats.customer.count }
              userRank={ stats.customer.user_rank }
              score={ stats.customer.average.score }
              correctAnswers={ stats.customer.average.correct_answers }
              wrongAnswers={ stats.customer.average.wrong_answers }
              emptyAnswers={ stats.customer.average.empty_answers }
            />

            <StatItem
              title={ data.entity.finished.student.data.school.data.fullname }
              count={ stats.school.count }
              userRank={ stats.school.user_rank }
              score={ stats.school.average.score }
              correctAnswers={ stats.school.average.correct_answers }
              wrongAnswers={ stats.school.average.wrong_answers }
              emptyAnswers={ stats.school.average.empty_answers }
            />

            <StatItem
              title={ data.entity.finished.student.data.school.data.county.fullname }
              count={ stats.county.count }
              userRank={ stats.county.user_rank }
              score={ stats.county.average.score }
              correctAnswers={ stats.county.average.correct_answers }
              wrongAnswers={ stats.county.average.wrong_answers }
              emptyAnswers={ stats.county.average.empty_answers }
            />

            <StatItem
              title={ data.entity.finished.student.data.school.data.city.fullname }
              count={ stats.city.count }
              userRank={ stats.city.user_rank }
              score={ stats.city.average.score }
              correctAnswers={ stats.city.average.correct_answers }
              wrongAnswers={ stats.city.average.wrong_answers }
              emptyAnswers={ stats.city.average.empty_answers }
            />

            <StatItem
              title={ t('Turkey') }
              count={ stats.country.count }
              userRank={ stats.country.user_rank }
              score={ stats.country.average.score }
              correctAnswers={ stats.country.average.correct_answers }
              wrongAnswers={ stats.country.average.wrong_answers }
              emptyAnswers={ stats.country.average.empty_answers }
            />
          </div>
        </div>
      }
    </div>
  );
};

const Item = ({
  no,
  item,
  type

}) => {
  const userAnswer = parseInt(item.user_answer);
  const correctAnswer = parseInt(type === 'questionbook' ? item.exam_item.correct_answer : item.correct_answer);

  return (
    <tr id={item.id} 
        className={
          userAnswer > 0 && userAnswer === correctAnswer ? 'bg-success text-white' : (
            userAnswer < 1 ? 'bg-secondary text-white' : (
              userAnswer !== correctAnswer ? 'bg-danger text-white' : ''
            )
          )
        }>
      <td className="bg-transparent" style={{color: 'inherit'}}>
        {no}
      </td>
      <td className="bg-transparent" style={{color: 'inherit'}}>
        {userAnswer > 0 ? userAnswer : ''}
      </td>
      <td className="bg-transparent" style={{color: 'inherit'}}>
        {correctAnswer}
      </td>
      <td className="bg-transparent" style={{color: 'inherit'}}>
        {
          userAnswer > 0 && userAnswer === correctAnswer ? 1 : (
            userAnswer < 1 ? 0 : (
              userAnswer !== correctAnswer ? 0 : ''
            )
          )
        }
      </td>
    </tr>
  );
};

const StatItem = ({
  title,
  count,
  userRank,
  score,
  correctAnswers,
  wrongAnswers,
  emptyAnswers

}) => {
  const appContext = useContext(AppContext);
  const t = appContext.t;

  return (
    <div className="border border-primary rounded mb-3 p-0">
      <h5 className="text-center m-0 p-2 rounded-top bg-primary text-white">
        { title }
      </h5>
      <div className="container">
        <div className="row p-2 bg-light border-bottom border-info">
          <div className="col-6 text-end bg">
            { t('Total Results') }:
          </div>
          <div className="col-6 text-start">
            { count }
          </div>
        </div>
        <div className="row p-2 border-bottom border-info">
          <div className="col-6 text-end">
            { t('Student Rank') }:
          </div>
          <div className="col-6 text-start">
            { userRank }
          </div>
        </div>
        <div className="row p-2 bg-light border-bottom border-info">
          <div className="col-6 text-end">
            { t('Average Score') }:
          </div>
          <div className="col-6 text-start">
            { score }
          </div>
        </div>
        <div className="row p-2 border-bottom border-info">
          <div className="col-6 text-end">
            { t('Average Correct Answers') }:
          </div>
          <div className="col-6 text-start">
            { correctAnswers }
          </div>
        </div>
        <div className="row p-2 bg-light border-bottom border-info">
          <div className="col-6 text-end">
            { t('Average Wrong Answers') }:
          </div>
          <div className="col-6 text-start">
            { wrongAnswers }
          </div>
        </div>
        <div className="row p-2">
          <div className="col-6 text-end">
            { t('Average Empty Answers') }:
          </div>
          <div className="col-6 text-start">
            { emptyAnswers }
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;