import { useEffect, useRef, useContext } from 'react';
import { getCurrentPath } from '../Functions';
import { AppContext } from '../Context';
 
const Header = () => {
  const appContext = useContext(AppContext);
  const headRef = appContext.headRef;
  const setLAStyle = appContext.setLAStyle;
  const hRef = useRef(null);

  // Initialization when the component mounts for the first time
  useEffect(() => {
    const handleResize = () => {
      if ( getCurrentPath() === '' ) {
        hRef.current.style.fontSize = '0px';
        hRef.current.style.margin = '0';
  
      } else {
        hRef.current.style.fontSize = '40px';
        hRef.current.style.margin = '20px';
      }
  
      let hStyles = window.getComputedStyle(headRef.current);
      let hHeight = headRef.current.offsetHeight + parseInt(hStyles.marginTop) + parseInt(hStyles.marginBottom);
      let lAStyle = {
        height: window.innerHeight - hHeight
      };
  
      setLAStyle(lAStyle);
    };

    window.addEventListener('resize', handleResize);
    window.screen.orientation.addEventListener('change', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.screen.orientation.removeEventListener('change', handleResize);
    };

  }, [headRef, setLAStyle]);

  return (
    <div className="Head" ref={headRef}>
      <h1 ref={hRef}>Learning Area</h1>
    </div>
  );
};
 
export default Header;