import { useRef, useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import { getUser, isUserAuthenticated, authorize, getQueryParam, exceptionX } from './Functions';
import Header from './components/Header';
import Body from './components/Body';
import ModalContent from './components/ModalContent';
import Loading from './components/Loading';
import './App.css';
import { AppContext } from './Context';
import tr from './languages/tr';
import axios   from "axios";
import { getCookie, setCookie } from "cookies-next";

axios.defaults.baseURL = process.env.NEXT_APP_API_BASE_URL;
axios.defaults.headers.common = {
  Authorization: `Bearer ${getCookie("access_token")}`,
};

Modal.setAppElement('#root');

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      tr: {
        translation: tr
      }
    },
    lng: "tr", // if you're using a language detector, do not define the lng option
    fallbackLng: "tr",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

function App()
{
  const { t } = useTranslation();
  const appRef = useRef(null);
  const headRef = useRef(null);
  const learningRef = useRef(null);
  const loadRef = useRef(null);
  const modalRef = useRef(null);
  const [ready, setReady] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState();
  const [lAStyle, setLAStyle] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [exception, setException] = useState(false);
  const [modal, setModal] = useState({
    type: 'simple',
    title: 'Hello',
    data: '<p>Hello World!</p>',
    style: {
      content: {}
    }
  });

  // Initialization when the component mounts for the first time
  useEffect(() => {
    isUserAuthenticated()
    .then(async (v) => {
      setIsAuthenticated(v);
      if ( ! v && getQueryParam('code') && getQueryParam('state') ) {
        /*await authorize();*/
        isUserAuthenticated(false).then((v) => {
          setIsAuthenticated(v);
          setUser(getUser());
          setReady(true);
        });

      } else {
        if ( v ) setUser(getUser());

        setReady(true);
      }

    }).catch(rejected => {
      setException(
        exceptionX(rejected)
      );
    });

  }, []);
  
  // Initialization when the component mounts for the first time or the exception is changed
  useEffect(() => {
    if ( exception && exception.status === false ) {
      setModal({
        type: 'exception',
        title: exception.level.charAt(0).toUpperCase() + exception.level.slice(1) + ': ' + exception.code,
        data: exception,
        style: {
          content: {
            minWidth: '300px',
            maxWidth: '400px',
            minHeight: '250px',
            maxHeight: '300px',
            transition: 'left 0.3s'
          }
        }
      });
      openModal();
    }

  }, [exception]);

  const afterOpenModal = () => {
    modalRef.current = document.querySelector('.ReactModal__Content');
    modal.style.content.left = parseInt((window.innerWidth - modalRef.current.offsetWidth) / 2);

    if ( exception && exception.status === false ) {
      setException(false);
    }
  }

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  // Function for choosing an answer
  const loading = useCallback((state = true) => {
    let loading = loadRef.current;
    
    if ( state ) {
      loading?.classList?.remove('d-none');

    } else {
      loading?.classList?.add('d-none');
    }

  }, []);

  return (
    <AppContext.Provider value={{
      t,
      ready, 
      isAuthenticated, 
      user, 
      appRef, 
      headRef, 
      learningRef, 
      loadRef, 
      lAStyle, 
      setLAStyle, 
      modal, 
      setModal, 
      openModal, 
      closeModal, 
      afterOpenModal, 
      loading, 
      setException
    }}>
      <div className="App" ref={appRef}>
        <Header />
        <Body />

        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={modal.style}
          contentLabel=""
        >
          <ModalContent />
        </Modal>

        <Loading />
      </div>
    </AppContext.Provider>
  );
}

export default App;