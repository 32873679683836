import { useRef, useEffect, useContext } from 'react';
import Draggable from 'react-draggable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsProgress } from '@fortawesome/free-solid-svg-icons';
import { simulateEvent } from '../Functions';
import { PenpanContext } from '../Context';

const DrawPanMenu = () => {
  const penpanContext = useContext(PenpanContext);
  const setLineColor = penpanContext.setLineColor;
  const setLineWidth = penpanContext.setLineWidth;
  const lineColor = penpanContext.lineColor;
  const lineWidth = penpanContext.lineWidth;
  const setDrawable = penpanContext.setDrawable;
  const drawable = penpanContext.drawable;
  const setDrawMode = penpanContext.setDrawMode;
  const drawMode = penpanContext.drawMode;
  const setPannable = penpanContext.setPannable;
  const menuRef = penpanContext.menuRef;
  const menuLeft = penpanContext.menuLeft;
  const setMenuLeft = penpanContext.setMenuLeft;
  const menuTop = penpanContext.menuTop;
  const setMenuTop = penpanContext.setMenuTop;
  const canvasRef = penpanContext.canvasRef;
  const zoomCenterRef = penpanContext.zoomCenterRef;
  const zoomInRef = penpanContext.zoomInRef;
  const zoomOutRef = penpanContext.zoomOutRef;
  const undoRef = penpanContext.undoRef;
  const redoRef = penpanContext.redoRef;
  const trashRef = penpanContext.trashRef;
  const exportRef = penpanContext.exportRef;
  const togglerRef = useRef(null);
  const draggableRef = useRef(null);

  useEffect(()=>{
    setLineWidth(2);

  },[])
  // Initialization when the component mounts for the first time
  useEffect(() => {
    const toggler = togglerRef.current;
    const draggable = draggableRef.current;
  
    const showMenu = async (e) => {
      let dragHandle = document.querySelectorAll('.drag-handle');
      draggable.state.x = 0;
      draggable.state.y = 0;

      await simulateEvent(dragHandle[0], 'mousedown');
      await simulateEvent(dragHandle[0], 'mousemove');
      await simulateEvent(dragHandle[0], 'mouseup');

      if ( ! menuRef.current.style.visibility || menuRef.current.style.visibility === 'hidden' ) {
        menuRef.current.style.visibility = 'visible';
        toggler.style.background = '#2e7bb5';
        toggler.style.borderColor = '#124f7a';
  
      } else {
        menuRef.current.style.visibility = 'hidden';
        toggler.style.background = '#212529';
        toggler.style.borderColor = '#373b3e';
      }
    }

    toggler.addEventListener('click', showMenu);
  
    return () => {
      toggler.removeEventListener('click', showMenu);
    };
  }, [menuRef, setMenuLeft, setMenuTop]);
  
  return (
    <>
      <button ref={togglerRef}
              className="position-absolute btn btn-dark draw-menu-toggler" 
              style={{top: '5px', left: '5px'}}>
        <FontAwesomeIcon icon={faBarsProgress} />
      </button>
      
      <Draggable
        handle='.drag-handle'
        defaultPosition={{x: 0, y: 0}}
        position={null}
        grid={[25, 25]}
        scale={1}
        ref={draggableRef}
      >
        <div className="Menu" style={{left: menuLeft + 'px', top: menuTop + 'px'}} ref={menuRef}>
          <div className="drag-handle"></div>
          <div className="block drawable">
            <input
              type="checkbox"
              id="drawable"
              checked={drawable}
              onChange={() => {
                if ( ! drawable ) {
                    setLineWidth(2)

                    setDrawable(true);
                  setPannable(false);
                  canvasRef.current.style.pointerEvents = 'auto';

                } else {
                  setDrawable(false);
                  setPannable(true);
                  canvasRef.current.style.pointerEvents = 'none';
                }
              }}
            />
            <label htmlFor="drawable">Drawable</label>
          </div>
          <div className="block draw-mode">
            <input 
              type="radio"
              name="draw_mode"
              id="pen"
              checked={drawable && drawMode}
              onClick={() => {
                  setLineWidth(2)

                  setDrawable(true);
                  setPannable(false);

                  if ( ! drawMode ) {
                    setDrawMode(true);
                  }

                  canvasRef.current.style.pointerEvents = 'auto';
                }
              }
              onChange={() => {
                  return;
                }
              }            
            />
            <label htmlFor="pen" className="pen" />
            <input 
              type="radio"
              name="draw_mode"
              id="eraser"
              checked={drawable && ! drawMode}
              onClick={() => {
                  setLineWidth(25)
                  setDrawable(true);
                  setPannable(false);

                  if ( drawMode ) {
                    setDrawMode(false);
                  }
                  
                  canvasRef.current.style.pointerEvents = 'auto';
                }
              }
              onChange={() => {
                  return;
                }
              }
            />
            <label htmlFor="eraser" className="eraser" title="Eraser" />
          </div>
          <div className="block draw-options">
            <div className="field">
              <label></label>
              <input
                type="color"
                value={lineColor}
                onChange={(e) => {
                  setLineColor(e.target.value);
                }}
              />
            </div>
            <div className="field">
              <label></label>
               <input
                type="range"
                min="1"
                max="20"
                value={lineWidth}
                onChange={(e) => {
                  setLineWidth(e.target.value);
                }}
              />
            </div>
            {/*
            <div className="field">
              <label>Opacity</label>
              <input
                type="range"
                min="1"
                max="100"
                onChange={(e) => {
                  setLineOpacity(e.target.value / 100);
                }}
              />
            </div>
            */}
          </div>
        {/*  <div className="block zoom">
            <div ref={zoomInRef} className="btn zoom-in" title="Zoom In" />
            <div ref={zoomCenterRef} className="btn zoom-center" title="Reset" />
            <div ref={zoomOutRef} className="btn zoom-out" title="Zoom Out" />
          </div>
         <div className="block do">
            <div  className="btn undo" title="Undo" />
            <div  className="btn redo" title="Redo" />
            <div  className="btn trash" title="Trash" />
           </div>*/}
          <div className="drag-handle"></div>
        </div>
      </Draggable>
    </>
  );
};
  
export default DrawPanMenu;