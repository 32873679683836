import { useContext, useState } from 'react';
import { authorize } from '../Functions';
import AppRouter from './AppRouter';
import axios from 'axios';
import { AppContext } from '../Context';
import  { Form,Input ,Button} from 'antd';
import {deleteCookie, setCookie} from "cookies-next"

const INITIAL_FORMDATA = {
  email: "",
  password: "",
};

const Body = () => {
  const [form] = Form.useForm();

  const [formData, setFormData] = useState(INITIAL_FORMDATA);

  const appContext = useContext(AppContext);
  const ready = appContext.ready;
  const isAuthenticated = appContext.isAuthenticated;
  const learningRef = appContext.learningRef;
  const lAStyle = appContext.lAStyle;
  const onSubmit=(submitData)=>{
    axios.post(process.env.REACT_APP_API_BASE_URL+"/api/login", submitData).then(async (userData) => {
      setCookie("access_token", userData.data.access_token);
      axios.defaults.headers.common = {
        Authorization: `Bearer ${userData.data.access_token}`,
      };
 window.location.replace("/")
    })
  }

  return (
    <div className="learning-area" ref={learningRef} style={lAStyle}>
      {
        ready &&
        (
          isAuthenticated ?
          ''//<Menu />
          :
          <div className="position-absolute top-50 start-0 w-100 z-3 opacity-75">
            <div className="w-25 mx-auto">
            <Form
                name="basic"
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                fields={Object.entries(formData).map(([name, value]) => ({
                  name,
                  value,
                }))}
                autoComplete="off"
                scrollToFirstError
                onValuesChange={(changedValues, allValues) => {
                  setFormData({ ...formData, ...allValues });
                }}
                onFinishFailed={() => console.log("failed")}
            >
              <Form.Item
                  name="email"
                  label="E-Posta"
                  rules={[
                    { required: true, message: "E-Posta Adresinizi Girin" },
                  ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                  name="password"
                  label="Şifre"
                  rules={[{ required: true, message: "Şifrenizi Giriniz" }]}
              >
                <Input.Password size="large" />
              </Form.Item>

              <Button
                  htmlType="submit"
                  size="large"
                  className=" btn btn-primary login"
              >
                Giriş Yap
              </Button>
            </Form>
            </div>

          </div>
        )
      }

      <AppRouter />
    </div>
  );
};
 
export default Body;