const tr = {
  'Turkey': 'Türkiye',
  'Lesson Entities': 'Ders Varlıkları',
  'Question Books': 'Soru Kitapçıkları',
  'Exams': 'Sınav Kitapçıkları',
  'Total Pages': 'Sayfa Sayısı',
  'Created': 'Oluşturuldu',
  'Finish': 'Bitir',
  'Results': 'Sonuçlar',
  'Finish Exam': 'Sınavı Bitir',
  'Finish Exam?': 'Sınavı Bitirmek mi?',
  'Are you sure you want to finish this exam?': 'Bu sınavı bitirmek istediğinden emin misin?',
  'Exam Finished.': 'Sınav Bitti.',
  'Exam Finished Successfully!': 'Sınav Başarıyla Tamamlandı!',
  'Now, you can check the exam result.': 'Artık sınav sonucunu kontrol edebilirsiniz.',
  'Exam Results': 'Sınav sonuçları',
  'Score': 'Puan',
  'Correct Answers': 'Doğru Yanıtlar',
  'Wrong Answers': 'Yanlış Yanıtlar',
  'Empty Answers': 'Boş Yanıtlar',
  'Total Questions': 'Soru Sayısı',
  'Show Details': 'Ayrıntılar',
  'Hide Details': 'Ayrıntılar',
  'Show Stats': 'İstatistikler',
  'Hide Stats': 'İstatistikler',
  'Details': 'Ayrıntılar',
  'Statistics': 'İstatistikler',
  'Answer': 'Yanıt',
  'Correct Answer': 'Doğru Yanıt',
  'Total Results': 'Toplam Sonuç Sayısı',
  'Student Rank': 'Öğrenci Sırası',
  'Average Score': 'Ortalama Puan',
  'Average Correct Answers': 'Ortalama Doğru Yanıtlar',
  'Average Wrong Answers': 'Ortalama Yanlış Yanıtlar',
  'Average Empty Answers': 'Ortalama Boş Yanıtlar'
}

export default tr;