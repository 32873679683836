import { useContext } from 'react';
import { AppContext } from '../Context';

const Loading = () => {
  const appContext = useContext(AppContext);
  const loadRef = appContext.loadRef;

  return (    
    <div ref={loadRef} className="position-absolute top-0 start-0 w-100 h-100 z-4 opacity-50 bg-white d-none">
      <div className="position-absolute top-50 start-0 w-100">
        <img src="/img/loading.gif" alt="loading ..." width="100px" className="d-block mx-auto" />
      </div>
    </div>
  );
};
 
export default Loading;