import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Penpan from '../pages/index';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' exact element={
            <Penpan />
          } 
        />
      </Routes>
    </Router>
  );
};
 
export default AppRouter;