import { useEffect, useRef, useState, useCallback, useContext } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import DOMPurify from 'dompurify';
import {finishExam, sendPageDrawing, sendPageQuestionAnswers, resetPageActivityDrawing, setPageAllQuestionAnswers, 
        setPageActivityDrawing, getPageActivityDrawing, setPageActivityQuestionAnswer, getPageActivityQuestionById, 
        createTargetPage, getAllExamData, getAllQBookData, getEntityPage, getEntityID, getEntityType, getEntity, drawStrokes, 
        getReturnURL, authorize, undoBuffer, exceptionX} from '../Functions';
import Menu from '../components/DrawPanMenu';
import Controls from '../components/Controls';
import { AppContext, PenpanContext } from '../Context';

const Penpan = () => {
  const appContext = useContext(AppContext);
  const t = appContext.t;
  const ready = appContext.ready;
  const isAuthenticated = appContext.isAuthenticated;
  const appRef = appContext.appRef;
  const headRef = appContext.headRef;
  const drawRef = appContext.learningRef;
  const setModal = appContext.setModal;
  const openModal = appContext.openModal;
  const closeModal = appContext.closeModal;
  const loading = appContext.loading;
  const setException = appContext.setException;
  const canvasRef = useRef(null);
  const ctxRef = useRef(null);
  const menuRef = useRef(null);
  const targetRef = useRef(null);
  const targetHTMLRef = useRef(null);
  const controlsRef = useRef(null);
  const zoomRef = useRef(null);
  const strokesRef = useRef(null);
  const zoomCenterRef = useRef(null);
  const zoomInRef = useRef(null);
  const zoomOutRef = useRef(null);
  const undoRef = useRef(null);
  const redoRef = useRef(null);
  const trashRef = useRef(null);
  const exportRef = useRef(null);
  const [entityType, setEntityType] = useState(null);
  const [entityID, setEntityID] = useState(null);
  const [entityPage, setEntityPage] = useState(1);
  const [entity, setEntity] = useState({});
  const [returnURL, setReturnURL] = useState(null);
  const [zoomState, setZoomState] = useState({});
  const [drawable, setDrawable] = useState(false);
  const [drawMode, setDrawMode] = useState(true);
  const [pannable, setPannable] = useState(true);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lineWidth, setLineWidth] = useState(5);
  const [lineColor, setLineColor] = useState("black");
  const [lineOpacity, setLineOpacity] = useState(1);
  const [dWidth, setDWidth] = useState(0);
  const [dHeight, setDHeight] = useState(0);
  const [menuLeft, setMenuLeft] = useState(0);
  const [menuTop, setMenuTop] = useState(0);
  const [targetHTML, setTargetHTML] = useState(null);
  const [targetStyle, setTargetStyle] = useState({});
  const [targetHTMLStyle, setTargetHTMLStyle] = useState({});
  const [lStyle, setLStyle] = useState({});
  const [totalPages, setTotalPages] = useState(1);
  const [isFinished, setIsFinished] = useState(false);

  // Function for loading the page
  const loadPage = useCallback(() => {
    if ( entityType && entityID ) {
      loading(true);

       createTargetPage(entityType, entityID, entityPage)
      .then(HTML => {


          if ( HTML ) {
            if ( typeof HTML === 'object' ) {
              setException(HTML);

            } else {
              let ent = getEntity();
              setEntity(ent);
              setIsFinished(
                typeof ent.finished === 'object' ? true : false
              );
              setTargetHTML(DOMPurify.sanitize(HTML));
               ent.exceptions?.forEach((exp) => {
                setException(exp);
              });
            }
          }

          loading(false);

        }).catch(error => {
           setException(
            exceptionX(error)
          );
          loading(false);
        });
      }
    
  }, [entityType, entityID, entityPage, loading, setException]);

  // Function for setting page answers
  const setPageAnswers = useCallback(() => {
    if ( ! entityPage ) return;

    let cAnswers = [];

     if ( entity.finished ) {

       entity?.data?.items?.forEach((item) => {

         if (item.data) {
            cAnswers[item.data.md5_id] = item.data.exam_item ? parseInt(item.data.exam_item.data.correct_answer) : 0;
            }else{
            cAnswers[item.md5_id] = item.exam_item ? parseInt(item.exam_item.correct_answer) : (item.correct_answer ? parseInt(item.correct_answer) : 0);
         }
        });
    }



    let qAnswerBoxes = document.querySelectorAll('.target .qPage .que .answerBox');

    for (let i = 0; i < qAnswerBoxes.length; ++i) {
      let itemMD5ID = qAnswerBoxes[i].getAttribute('data-imd5');

       if ( itemMD5ID ) {
        let ca = typeof cAnswers[itemMD5ID] !== 'undefined' ? cAnswers[itemMD5ID] : 0;
        let q = getPageActivityQuestionById(entityPage, itemMD5ID);


        if ( q ) {
          let answer = q.answer ? parseInt(q.answer) : 0;
  
          for (let j = 0; j < qAnswerBoxes[i].children.length; ++j) {
            if ( answer === j + 1 ) {
              qAnswerBoxes[i].children[j].children[0]?.classList.add('selected');
            }

            if ( ca > 0 ) {
              if ( ca === answer ) {
                qAnswerBoxes[i].children[j].children[0]?.classList.add('green');
  
              } else {
                if ( answer === j + 1 ) {
                  qAnswerBoxes[i].children[j].children[0]?.classList.add('red');
                }

                if ( ca === j + 1 ) {
                  qAnswerBoxes[i].children[j].children[0]?.classList.add('selected');
                  qAnswerBoxes[i].children[j].children[0]?.classList.add('yellow');
                }
              }
            }
          }
        }
      }
    }
  }, [entityPage, entity.finished, entity.data]);

   // Function for choosing an answer
  const chooseAnswer = useCallback((e) => {
    if ( ! entityPage || entity.finished ) return;

    let itemMD5ID = e.target.parentElement.parentElement.getAttribute('data-imd5');
    let siblings = e.target.parentElement.parentElement.children;
    var answer = 0;

    for (let i = 0; i < siblings.length; ++i) {
      if (siblings[i].children[0] !== e.target) {
        siblings[i].children[0].classList.remove("selected");

      } else if ( ! e.target.classList.contains('selected') ) {
        answer = i + 1;
      }
    }

    if ( e.target.classList.contains('selected') ) {
      e.target.classList.remove("selected");

    } else {
      e.target.classList.add("selected");
    }

    setPageActivityQuestionAnswer(entityPage, itemMD5ID, answer);

  }, [entityPage, entity.finished]);

  const openContent = useCallback((e) => {
    let title = e.currentTarget.getAttribute('title');
    let type = e.currentTarget.getAttribute('data-type');
    let object = e.currentTarget.getAttribute('data-object');

    setModal({
      type: 'content',
      title: title,
      data: {
        type: type,
        title: title,
        object: object
      },
      style: {
        content: {
          minWidth: window.innerWidth > 370 ? '350px' : '300px',
          maxWidth: window.innerWidth < 800 ? '98%' : (
            window.innerWidth < 1400 ? '90%' : '1300px'
          ),
          minHeight: '300px',
          maxHeight: window.innerWidth < 500 ? (['text', 'pdf'].includes(type) ? '90%' : '300px') : (
            window.innerWidth < 800 ? (['text', 'pdf'].includes(type) ? '90%' : '500px') : '90%'
          ),
          transition: 'left 0.3s'
        }
      }
    });
    openModal();

  }, [setModal, openModal]);

  const results = (e) => {
    let modal = {
      type: 'results',
      title: t('Exam Results'),
      data: {
        entity: entity
      },
      style: {
        content: {
          minWidth: '300px',
          maxWidth: window.innerWidth < 800 ? '90%' : '700px',
          minHeight: '300px',
          maxHeight: '90%',
          transition: 'left 0.3s'
        }
      }
    };

    setModal(modal);
    openModal();
  };

  // Function on display a warning before finishing the exam
  const finish = (e) => {
    localStorage.clear("entities")
    let modal = {
      type: 'finish',
      title: "",
      data: {},
      style: {
        content: {
          minWidth: '300px',
          maxWidth: window.innerWidth < 800 ? '90%' : '500px',
          minHeight: '300px',
          maxHeight: window.innerWidth < 800 ? '300px' : '300px',
          transition: 'left 0.3s'
        }
      }
    };
    modal.finishExam = () => {
      submitPage(entityPage);
      loading(true);
      closeModal();
    
      finishExam(getEntityType(true), getEntityID(true))
      .then(data => {
        if ( data ) {
          if ( data.status ) {
            loadPage();
            setModal({
              type: 'finished',
              title: t('Exam Finished.'),
              data: {},
              style: {
                content: {
                  minWidth: '300px',
                  maxWidth: window.innerWidth < 800 ? '90%' : '500px',
                  minHeight: '300px',
                  maxHeight: window.innerWidth < 800 ? '300px' : '300px',
                  transition: 'left 0.3s'
                }  
              }
            });
            openModal();
    
          } else {
            setException(data);
          }
        }

        loading(false);

      }).catch(error => {
        setException(
          exceptionX(error)
        );
        loading(false);
      });
    };

    setModal(modal);
    openModal();
  };
  
  // Function on changing the page
  const submitPage = async (pageNo,finish=false) => {
    loading(true);
     if ( !isFinished ) {
      sendPageQuestionAnswers(pageNo,finish)
      .then(data => {
         if ( data ) {
          if ( data.status ) {
             setPageAllQuestionAnswers(pageNo, {});

          } else {
            setException(data);
          }
        }
            
      }).catch(error => {
        setException(
          exceptionX(error)
        );
      });
    }
  
    sendPageDrawing(pageNo, canvasRef.current.toDataURL())
    .then(data => {
      if ( data ) {
        if ( data.status ) {
          resetPageActivityDrawing(pageNo);
              
        } else {
          setException(data);
        }
      }
          
    }).catch(error => {
      setException(
        exceptionX(error)
      );
    });
  };
  
  // Function for starting the drawing
  const startDrawing = (e) => {
    if ( ! drawable ) {
      return;
    }

    if (drawMode) ctxRef.current.beginPath();

    let offsets = getEventOffsets(e);
    strokesRef.current = {
      points: [{
        x: offsets.x,
        y: offsets.y
      }],
      draw: drawMode,
      color: lineColor,
      width: lineWidth,
      opacity: lineOpacity
    };

    ctxRef.current.moveTo(
      offsets.x,
      offsets.y
    );
    setIsDrawing(true);

    document.body.style.cursor = drawMode ? 'progress' : 'grabbing';
  };
  
  // Function for doing the drawing
  const draw = (e) => {
    if ( ! drawable || ! isDrawing ) {
      return;
    }

    let offsets = getEventOffsets(e);

    strokesRef.current.points.push({
      x: offsets.x, 
      y: offsets.y
    });

    ctxRef.current.lineTo(
      offsets.x,
      offsets.y
    );

    ctxRef.current.stroke();
  };
  
  // Function for ending the drawing
  const endDrawing = () => {
    if ( ! drawable || ! isDrawing ) {
      return;
    }

    if (drawMode) ctxRef.current.closePath();

    setIsDrawing(false);
    addUndoable(strokesRef.current);

    strokesRef.current = {};
    document.body.style.cursor = 'default';
  };

  // Function for erasing the drawing
  const erase = (e) => {
    if ( ! drawable || ! isDrawing || ! strokesRef.current || ! strokesRef.current.points ) return;

    let offsets = getEventOffsets(e);

    strokesRef.current.points.push({
      x: offsets.x,
      y: offsets.y
    });

    ctxRef.current.clearRect(offsets.x - lineWidth / 2, offsets.y - lineWidth / 2, lineWidth, lineWidth);
  };

  // Function for updating undo/redo buttons
  const updateUndo = () => {
    undoBuffer.canUndo ? trashRef.current?.classList.remove("disabled") : trashRef.current?.classList.add("disabled");
    undoBuffer.canUndo ? undoRef.current?.classList.remove("disabled") : undoRef.current?.classList.add("disabled");
    undoBuffer.canRedo ? redoRef.current?.classList.remove("disabled") : redoRef.current?.classList.add("disabled");
  };

  // Function for add an undoable
  const addUndoable = (data) => {
    undoBuffer.update({...data});
    setPageActivityDrawing(entityPage, undoBuffer.buffer);
    updateUndo();
  };

  const getEventOffsets = (e) => {
    let scale = typeof zoomState.scale !== 'undefined' ? zoomState.scale : 1;
    let rect = e.target.getBoundingClientRect();
    let ox = 0;
    let oy = 0;

    if ( ['mousedown', 'mousemove', 'mouseup'].includes(e.nativeEvent.type) ) {
      ox = e.nativeEvent.offsetX;
      oy = e.nativeEvent.offsetY;

    } else {
      ox = (e.touches[0].pageX - window.scrollX - rect.left) / scale;
      oy = (e.touches[0].pageY - window.scrollY - rect.top) / scale;
    }

    return {
      x: ox,
      y: oy
    }
  };

  const chooseEntity = async (e) => {
    let data = {};
    data.q_books = [];
    data.exams = [];

    loading(true);

    await getAllQBookData()
    .then(response => {
      if ( response && response.status ) {
        data.q_books = response.data.data;

      } else {
        setException(response);
      }

    }).catch(rejected => {
      setException(
        exceptionX(rejected)
      );
    });

    await getAllExamData()
    .then(response => {
      if ( response && response.status ) {
        data.exams = response.data.data;

      } else {
        setException(response);
      }

      loading(false);

    }).catch(rejected => {
      setException(
        exceptionX(rejected)
      );
      loading(false);
    });

    setModal({
      type: 'entities',
      title: t('Lesson Entities'),
      data: data,
      style: {
        content: {
          minWidth: window.innerWidth > 370 ? '350px' : '300px',
          maxWidth: window.innerWidth < 1100 ? (window.innerWidth < 700 ? '98%' : '90%') : '1000px',
          minHeight: '300px',
          maxHeight: '90%',
          transition: 'left 0.3s'
        }
      }
    });
    openModal();
  };  

  // Hook when the component mounts for the first time
  useEffect(() => {    
    setReturnURL(
      getReturnURL()
    );
    setEntityType(
      getEntityType()
    );
    setEntityID(
      getEntityID()
    );
    setEntityPage(
      getEntityPage()
    );

  }, []);

  // Initialization when the component mounts for the first time or the user is changed
  useEffect(() => {
    const handleResize = () => {
      let hStyles = window.getComputedStyle(headRef.current);
      let cStyles = controlsRef.current ? window.getComputedStyle(controlsRef.current) : {marginTop: 0, marginBottom: 0};
      let mStyles = window.getComputedStyle(menuRef.current);
      let dStyles = window.getComputedStyle(drawRef.current);
      let dTStyles = window.getComputedStyle(targetHTMLRef.current);
      let hHeight = headRef.current.offsetHeight + parseInt(hStyles.marginTop) + parseInt(hStyles.marginBottom);
      let mWidth = menuRef.current.offsetWidth + parseInt(mStyles.marginRight) + parseInt(mStyles.marginLeft);
      let mHeight = menuRef.current.offsetHeight + parseInt(mStyles.marginTop) + parseInt(mStyles.marginBottom);
      let cHeight = (controlsRef.current ? controlsRef.current.offsetHeight : mHeight) + parseInt(cStyles.marginTop) + parseInt(cStyles.marginBottom);
      let dWidth = appRef.current.offsetWidth - 2 * parseInt(dStyles.borderWidth);
      let dHeight = window.innerHeight - hHeight - 2 * parseInt(dStyles.borderWidth);
      let menuLeft = parseInt((window.innerWidth - mWidth) / 2);
      let tHeight = dHeight - mHeight - cHeight - 4;
      let A4PaperRatio = 1.414;
      let tWidth = Math.min(dWidth - 2, Math.floor((dHeight / dWidth) * dHeight * A4PaperRatio));
      let targetStyle = {
        width: tWidth + 'px',
        height: tHeight + 'px',
        marginTop: (mHeight + 2) + 'px'
      };
      let lStyle = {
        width: dWidth + 'px',
        height: dHeight + 'px'
      };
      let tDWidth = parseInt(dTStyles.width);
      let tDHeight = parseInt(dTStyles.height);
      let tDHScale = tHeight / tDHeight;
      let tDWScale = tWidth / tDWidth;
      let tDScale = tWidth < dWidth - 2 || tDHScale < tDWScale ? tDHScale : tDWScale;
      let tDPositionX = '-50%'; // (- Math.round((tDWidth - tWidth) * tDHScale)) + 'px';
      let tDPositionY = '-50%'; // (- Math.round((tDHeight - tHeight) * tDHScale)) + 'px';
      let targetHTMLStyle = {
        transform: 'translate(' + tDPositionX + ',' + tDPositionY + ') scale(' + tDScale + ')'
      };
  
      setDWidth(dWidth);
      setDHeight(dHeight);
      setMenuLeft(menuLeft);
      setLStyle(lStyle);
      setTargetStyle(targetStyle);
      setTargetHTMLStyle(targetHTMLStyle);
    };

    window.addEventListener('resize', handleResize);
    window.screen.orientation.addEventListener('change', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.screen.orientation.removeEventListener('change', handleResize);
    };

  }, [appRef, headRef, drawRef, ready]);

  // Hook when the component mounts for the first time or canvas config changes
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    strokesRef.current = {};
    ctx.lineCap = "round";
    ctx.lineJoin = "round";
    ctx.globalAlpha = lineOpacity;
    ctx.strokeStyle = lineColor;
    ctx.lineWidth = lineWidth;
    ctxRef.current = ctx;

    if ( ! drawable ) {
      canvas.style.pointerEvents = 'none';
    }
  
  }, [lineColor, lineOpacity, lineWidth, drawable]);

  // Hook when the component mounts for the first time
  useEffect(() => {
    if ( ! undoRef.current || ! redoRef.current || ! trashRef.current || ! zoomCenterRef.current || ! zoomInRef.current || 
         ! zoomOutRef.current || ! exportRef.current ) return;

    const zoomCenterBtn = zoomCenterRef.current;
    const zoomInBtn = zoomInRef.current;
    const zoomOutBtn = zoomOutRef.current;
    const exportBtn = exportRef.current;
    const undoBtn = undoRef.current;
    const redoBtn = redoRef.current;
    const trashBtn = trashRef.current;

    const zoomCenter = (e) => {
      zoomRef.current.resetTransform();
    }
    
    const zoomIn = (e) => {
      zoomRef.current.zoomIn();
    };
    
    const zoomOut = (e) => {
      zoomRef.current.zoomOut();
    };
  
    const canvasImage = (e) => {
      var a = document.createElement("a");
      a.href = canvasRef.current.toDataURL();
      a.download = "drawing.png";
  
      a.click();
    };

    const undo = (e) => {
      ctxRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

      let buffer = undoBuffer.undo();

      (async () => {
        let i = 0;

        for (const strokes of buffer) {
          await drawStrokes(ctxRef.current, strokes);

          if ( i === buffer.length - 1 ) {
            if (strokes.width) setLineWidth(strokes.width);
            if (strokes.color) setLineColor(strokes.color);
            if (strokes.opacity) setLineOpacity(strokes.opacity);
          } 

          i++;
        }

      })();

      updateUndo();
    };
    
    const redo = (e) => {
      let strokes = undoBuffer.redo();
      
      drawStrokes(ctxRef.current, strokes);

      if (strokes.width) setLineWidth(strokes.width);
      if (strokes.color) setLineColor(strokes.color);
      if (strokes.opacity) setLineOpacity(strokes.opacity);

      updateUndo();
    };
  
    const trash = (e) => {
      ctxRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      undoBuffer.update('<<TRASHED>>');
      updateUndo();
      trashRef.current.classList.add("disabled");
    };
  
    zoomCenterBtn.addEventListener("click", zoomCenter);
    zoomInBtn.addEventListener("click", zoomIn);
    zoomOutBtn.addEventListener("click", zoomOut);
    exportBtn.addEventListener("click", canvasImage);
    undoBtn.addEventListener("click", undo);
    redoBtn.addEventListener("click", redo);
    trashBtn.addEventListener("click", trash);
    updateUndo();
    setLineOpacity(1);
  
    return () => {
      zoomCenterBtn.removeEventListener("click", zoomCenter);
      zoomInBtn.removeEventListener("click", zoomIn);
      zoomOutBtn.removeEventListener("click", zoomOut);
      exportBtn.removeEventListener("click", canvasImage);
      undoBtn.removeEventListener("click", undo);
      redoBtn.removeEventListener("click", redo);
      trashBtn.removeEventListener("click", trash);
    };
  
  }, []);

  // Hook when the component mounts for the first time or the entity is changed
  useEffect(() => {    
    if ( isAuthenticated ) {
      loadPage();

    } else if ( ready && entityType && entityID ) {
     /* authorize();*/
    }

  }, [entityType, entityID, entityPage, ready, isAuthenticated, loadPage]);

  // Hook when the component mounts for the first time or entity image is changed
  useEffect(() => {
    ctxRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    if ( ! entityPage ) return;

    let drawing = getPageActivityDrawing(entityPage);
    drawing = drawing ? drawing : [];

    if ( entity.image && (drawing.length < 1 || typeof drawing[0] !== 'string') ) drawing.unshift(entity.image);

     undoBuffer.buffer = [...drawing];

    updateUndo();

    (async () => {
      for (const strokes of undoBuffer.buffer) {
        await drawStrokes(ctxRef.current, strokes);
      }

    })();
    
  }, [entityPage, entity.image]);

  // Hook when the component mounts for the first time or total pages is changed
  useEffect(() => {
    setTotalPages(entity.total_pages);

    if ( entityPage > entity.total_pages ) setEntityPage(entity.total_pages);
  
  }, [entity.total_pages, entityPage]);

  // Hook when the component mounts for the first time or the target data is updated
  useEffect(() => {
    if ( ! targetHTML || ! entityPage ) return;

    let qAnswers = document.querySelectorAll('.target .qPage .que .area');
    let cButtons = document.querySelectorAll('.content-button');
  
    for (let i = 0; i < qAnswers.length; ++i) {
      qAnswers[i].addEventListener("click", chooseAnswer);
    }
  
    for (let i = 0; i < cButtons.length; ++i) {
      cButtons[i].addEventListener("click", openContent);
    }

    setPageAnswers();
  
    return () => {
      let qAnswers = document.querySelectorAll('.target .qPage .que .area');
      let cButtons = document.querySelectorAll('.content-button');
      
      for (let i = 0; i < qAnswers.length; ++i) {
        qAnswers[i].removeEventListener("click", chooseAnswer);
      }
  
      for (let i = 0; i < cButtons.length; ++i) {
        cButtons[i].removeEventListener("click", openContent);
      }
    };

  }, [targetHTML, entityPage, chooseAnswer, setPageAnswers, openContent]);

  return (
    <PenpanContext.Provider value={{ 
      setLineColor,
      setLineWidth,
      setLineOpacity,
      setDrawable,
      setDrawMode,
      setPannable,
      setEntityPage,
      finish,
      results,
      submitPage,
      chooseEntity,
      setMenuLeft,
      setMenuTop,
      lineColor,
      lineWidth,
      lineOpacity,
      drawable,
      drawMode,
      menuLeft,
      menuTop,
      menuRef,
      canvasRef,
      controlsRef,
      zoomRef,
      zoomCenterRef,
      zoomInRef,
      zoomOutRef,
      undoRef,
      redoRef,
      trashRef,
      exportRef,
      returnURL,
      entityPage,
      totalPages,
      isFinished,
      setIsFinished
    }}>
      <Menu />

      <TransformWrapper
        initialScale={1}
        minScale={0.2}
        initialPositionX={0}
        initialPositionY={0}
        limitToBounds={false}
        panning={{ disabled: ! pannable }}
        pinch={{ step: 3 }}
        ref={zoomRef}
        onZoom={(ref, e) => {
          setZoomState(ref.state)
        }}
        doubleClick={{
          mode: "reset",
          step: 0.7,
          animationTime: 200,
          animationType: "easeOut",
          excluded: [],
        }}
      >
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <TransformComponent>
          <div className="target-wrapper" style={lStyle}>
            <div className="target" style={targetStyle} ref={targetRef}>
              <div 
                className="target-html" 
                style={targetHTMLStyle} 
                ref={targetHTMLRef} 
                dangerouslySetInnerHTML={{__html: targetHTML}} 
              />
            </div>
            <canvas
              onTouchStart={startDrawing}
              onTouchMove={drawMode ? draw : erase}
              onTouchEnd={endDrawing}
              onMouseDown={startDrawing}
              onMouseMove={drawMode ? draw : erase}
              onMouseUp={endDrawing}
              dwidth={`${dWidth}px`}
              dheight={`${dHeight}px`}
              width={`${2000}px`}
              height={`${2000}px`}
              ref={canvasRef}
            />
          </div>
        </TransformComponent>
      )}
      </TransformWrapper>

      <Controls />
    </PenpanContext.Provider>
  );
};
      
export default Penpan;