import { createContext } from 'react';

const AppContext = createContext();
const PenpanContext = createContext();
const ModalContext = createContext();

export {
  AppContext,
  PenpanContext,
  ModalContext
};